import React from "react";
import { Outlet } from "react-router-dom";

export default function LayoutPublicChat() {
  return (
    <main className="relative flex flex-grow flex-col bg-[#FFFEFB]">
      <Outlet />

      <div className="bg-[#F3F0E6] py-10 text-[18px] text-dark-blue">
        <div className="mb-2 flex w-full flex-row items-center justify-center gap-5">
          <a
            target="_blank"
            rel="noreferrer"
            className="font-interlight hover:underline"
            href="https://fenixbegravning.se/pdf/Fenix-Allmanna-villkor.pdf"
          >
            Allmänna villkor
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="font-interlight hover:underline"
            href="https://fenixbegravning.se/pdf/Fenix-Integritetspolicy.pdf"
          >
            Integritetspolicy
          </a>
        </div>
        <div className="text-center font-interlight">
          © Fenix Family AB 2023
        </div>
      </div>
    </main>
  );
}
