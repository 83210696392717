export type PartnerType = {
  title: string;
  pageTitle: string;
  url: string;
  pkg?: number;
  price?: string;
  logo?: URL;
  tagline?: string;
};

export const defaultPackagePrice = "2999";

export const partners: PartnerType[] = [
  {
    title: "Bostadsratterna",
    pageTitle: "Bostadsratterna - Fenix",
    url: "bostadsratterna",
    logo: new URL(
      "../assets/images/bostadsratterna_logotyp_cmyk.png",
      import.meta.url
    ),
    pkg: process.env.NODE_ENV === "production" ? 107 : 119,
    price: "2545",
  },
  {
    title: "SocialMedia",
    pageTitle: "Fenix",
    url: "social-media",
  },
  {
    title: "Fenix society",
    pageTitle: "Fenix",
    url: "fenix-society",
  },
  {
    title: "Fenix family",
    pageTitle: "Fenix",
    url: "fenixfamily",
    pkg: 99,
    price: "10",
  },
  {
    title: "ActiveCampaign",
    pageTitle: "Fenix",
    url: "active-campaign",
    pkg: 101,
    price: "1499",
  },
  {
    title: "Novu",
    pageTitle: "Novu - Fenix",
    url: "novu",
    logo: new URL("../assets/images/novu.png", import.meta.url),
    price: "0",
    pkg: 4,
  },
  {
    title: "SKFS",
    pageTitle: "SKFS - Fenix",
    url: "skfs",
    logo: new URL("../assets/images/skfs.png", import.meta.url),
    pkg: 5,
    price: "0",
    tagline:
      "All livsplanering du behöver kostnadsfritt för SKFS guld-kunder (Ordinarie pris 2999:-)",
  },
  {
    title: "Svensk Fond och Försäkring 695",
    pageTitle: "Svensk Fond och Försäkring - Fenix",
    url: "svenskfond",
    pkg: 6,
    price: "695",
  },
  {
    title: "Strategipension",
    pageTitle: "Strategipension - Fenix",
    url: "strategipension",
    logo: new URL("../assets/images/strategipension.png", import.meta.url),
    pkg: 7,
    price: "2649",
  },
  {
    title: "Tydliga",
    pageTitle: "Tydliga - Fenix",
    url: "tydliga",
    logo: new URL("../assets/images/tydliga_partner.jpg", import.meta.url),
    pkg: 8,
    price: "2649",
  },
  {
    title: "Claudiu",
    pageTitle: "Fenix",
    url: "claudiu",
    pkg: 9,
    price: "0",
  },
  {
    title: "SGJ Capital",
    pageTitle: "SGJ Capital - Fenix",
    url: "sgjcapital",
    logo: new URL("../assets/images/sgjcapital.jpg", import.meta.url),
    pkg: 105,
    price: "0",
  },
  {
    title: "Lindhe & Partners",
    pageTitle: "Lindhe & Partners - Fenix",
    url: "lindhe",
    logo: new URL("../assets/images/lindhe.jpg", import.meta.url),
    pkg: 106,
    price: "0",
  },
  {
    title: "Mandat Kapitalförvaltning AB",
    pageTitle: "Mandat - Fenix",
    url: "mandat",
    logo: new URL("../assets/images/mandat.jpg", import.meta.url),
    pkg: 104,
    price: "0",
  },
  {
    title: "Aktiv Liv och Finans",
    pageTitle: "Aktiv Liv och Finans - Fenix",
    url: "alf",
    logo: new URL("../assets/images/alf.jpg", import.meta.url),
    pkg: 109,
    price: "0",
  },
  {
    title: "Fenix",
    pageTitle: "Fenix",
    url: "fenix",
    pkg: process.env.NODE_ENV === "production" ? 110 : 120,
    price: "299",
  },
];

export function getPartnerByUrl(url: string | null) {
  if (url === null) {
    return null;
  }

  const partnerExist = partners.find((p) => url === p.url);
  return partnerExist ? partnerExist : null;
}
