// Import Swiper styles
import "swiper/swiper-bundle.css";

import React, { useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import ArticleGridItem from "../ArticleGridItem";

export default function ArticlesSlider({
  articles,
  init,
  quantity,
}: {
  articles: any;
  init: boolean;
  quantity: number;
}) {
  const [pagesEl, setPagesEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <div className="slider-popular relative">
        <Swiper
          modules={[Pagination]}
          keyboard={true}
          allowTouchMove={true}
          simulateTouch={true}
          edgeSwipeDetection={true}
          touchReleaseOnEdges={true}
          mousewheel={{
            releaseOnEdges: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1.23,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2.3,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: quantity,
              spaceBetween: 30,
              init: init,
            },
          }}
          pagination={{
            clickable: true,
            el: pagesEl,
          }}
          grabCursor={true}
          className="swiper-popular -mr-5 md:-mr-0"
        >
          {articles.slice(0, 4).map((item: any, index: number) => {
            return (
              <SwiperSlide key={index}>
                <ArticleGridItem item={item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          ref={(node) => setPagesEl(node)}
          className="swiper-pagination swiper-pagination--articles swiper-pagination-clickable swiper-pagination-bullets block lg:hidden"
        ></div>
      </div>
    </>
  );
}
