import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { steps } from "../assets/data/datalist";
import AdvisorToggleSection from "../components/AdvisorToggleSection";
import ConversationEmbedded from "../components/ConversationEmbedded";
import Footer from "../components/Footer";
import GifSection from "../components/GifSection";
import HeadMenu from "../components/HeadMenu";
import LoaderScreen from "../components/LoaderScreen";
import PackageSection from "../components/PackageSection";
import TrustBox from "../components/TrustBox";
import VideoItem from "../components/VideoItem";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { useMediaQuery } from "../services/hooks/useMediaQuery";
import {
  getBankIdIcon,
  getQuoteIcon,
  getShortDownArrowIcon,
} from "../services/icons";
import {
  defaultPackagePrice,
  getPartnerByUrl,
  PartnerType,
} from "../services/partners";
import {
  frPkgKey,
  homeRedirectKey,
  partnerKey,
  setCookie,
  setInStorage,
} from "../services/storage";
import { useScrollPosition } from "../services/utils";

const heroImg = new URL("../assets/images/team.jpg", import.meta.url);

export default function AffiliatePage() {
  const [partner, setPartner] = useState<PartnerType>();
  const [loading, setLoading] = useState(true);
  const [showConversationModal, setShowConversationModal] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const scrollPosition = useScrollPosition();
  const navigate = useNavigate();
  const { pt } = useParams<{
    pt: string;
  }>();

  useEffect(() => {
    setInStorage(homeRedirectKey, window.location.pathname);

    if (pt) {
      const partnerExist = getPartnerByUrl(pt);
      if (partnerExist) {
        setCookie(partnerKey, pt);
        setPartner(partnerExist);
      } else {
        navigate("/partner/");
        setLoading(false);
      }
    } else {
      setPartner(null);
    }

    const frPkgParam = new URLSearchParams(window.location.search).get("fp");
    if (frPkgParam && frPkgParam === frPkgKey) {
      setCookie(frPkgKey, frPkgKey);
    }
  }, []);

  useEffect(() => {
    if (partner !== undefined) {
      setLoading(false);
    }
    if (partner) {
      document.title = partner.pageTitle;
    }
  }, [partner]);

  useEffect(() => {
    if (showConversationModal) {
      setShowConversationModal(false);
    }
  }, [showConversationModal]);

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }

  const renderLoginButton = (buttonClass: string, iconColor: string) => {
    return (
      <Link
        to="/login"
        className={
          `inline-flex items-center gap-2 rounded border-[1px] border-white bg-transparent py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-[#fff] hover:border-[#CECABA] hover:text-[#CECABA] lg:gap-3 lg:self-end` +
          buttonClass
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon(iconColor, "20", "100%")
          : getBankIdIcon("#fff", "20", "100%")}
        Kom igång
      </Link>
    );
  };

  if (loading) {
    return (
      <>
        <LoaderScreen />
      </>
    );
  }

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-white",
            "fixed z-30 w-full transition-colors"
          )}
        >
          <HeadMenu
            isPartner={true}
            affiliateLogo={partner?.logo?.href}
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={scrollPosition > 84 ? "#0C2632" : "#0C2632"}
          />
        </div>
        <div className="relative mt-24 w-full text-white">
          <div className="flex flex-col pt-2 lg:flex-row lg:gap-5 lg:pt-0">
            <div className="w-full lg:max-w-[50%]">
              <img
                src={heroImg.href}
                alt="Fenix"
                className="lg:rounded-tr-0 relative h-auto min-h-[345px] w-[100%] max-w-[85%] rounded-tr-[13px] rounded-br-[13px] object-cover object-top lg:h-full lg:min-h-[640px] lg:max-w-[100%] lg:rounded-l-none lg:rounded-br-[24px] 2k:min-h-[800px] 4k:min-h-[1200px]"
                loading="lazy"
              />
            </div>
            <div className="w-full lg:max-w-[50%]">
              <div className="flex h-full flex-col justify-center pr-5 pl-5 text-deep-blue ">
                {partner?.logo && (
                  <img
                    src={partner.logo.href}
                    alt="partner logo"
                    className="mb-4 mt-10 max-w-[215px] object-contain lg:mt-0 lg:max-w-[256px]"
                    loading="lazy"
                  />
                )}
                <h1 className="mb-4 font-interblack text-[36px] italic leading-[40px] lg:mb-4 lg:text-[64px] lg:leading-[70px]">
                  Livsplanering - tryggt och enkelt
                </h1>
                <p className="max-w-[270px] font-interlight text-[18px] italic leading-[24px] lg:max-w-full">
                  {partner?.tagline ? (
                    partner?.tagline
                  ) : (
                    <>
                      All livsplanering du behöver för{" "}
                      <span className="font-intermedium text-[#5F3741]">
                        {partner?.price ? partner.price : defaultPackagePrice}:-
                      </span>{" "}
                      {partner?.price && `(Ordinarie pris 2999:-)`}
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full bg-white pb-20 text-deep-blue">
          <div className="mx-auto px-5 pb-16 pt-20 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-16 lg:pt-28 xl:max-w-[1360px]">
            <div className="mx-auto w-full max-w-[1100px]">
              <h2 className="mb-3 max-w-[900px] font-interblack text-[28px] italic leading-[31px] lg:text-[48px] lg:leading-[53px]">
                Gör upp en plan utifrån din nuvarande och framtida
                livssituation.
              </h2>
              <p className="mb-3 max-w-[900px] whitespace-pre-wrap font-interlight italic">
                Juridik, avtal och testamenten kan upplevas som krångligt,
                kanske till och med lite obehagligt. Tillsammans ska vi få det
                att kännas precis tvärtom: tryggt, enkelt och självklart. Att ta
                tag i de här frågorna kommer inte bara att ge dig lugn och ro
                här och nu. Det kommer även underlätta framtiden för både dig
                och dina närmaste.
              </p>
              <span className="mt-0 inline-flex items-center font-intermedium text-[14px] uppercase tracking-[0.12em]">
                Så här funkar det {getShortDownArrowIcon("#0C2632")}
              </span>
            </div>
          </div>
          <PackageSection
            price={partner?.price ? partner.price : defaultPackagePrice}
          />
        </div>
        <div className="relative w-full bg-[#F3F0E680] text-deep-blue">
          <GifSection buttonOnClick={() => setShowConversationModal(true)} />
          <div className="mx-auto px-5 pt-16 pb-10 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-20 lg:pt-24 xl:max-w-[1360px]">
            <div className="flex flex-col gap-3 lg:flex-row lg:items-end lg:justify-between">
              <div className="w-full max-w-[760px]">
                <VideoItem />
              </div>
              <div className="max-w-[530px]">
                <h3 className="mb-3 mt-10 font-interblack text-[22px] font-extrabold italic leading-[22px] lg:text-[36px] lg:leading-[40px]">
                  Ett snabbt, tryggt och enkelt sätt att reda ut svåra saker.
                </h3>
                <p className="font-interlight text-base leading-[22px]">
                  Korrekt skrivna avtal för livets olika skeden kan bespara dig
                  och din familj mycket besvär. Klokt sparande och rätt
                  försäkringar är två andra viktiga bitar att lägga i
                  livspusslet. I vår värld är de här sakerna inte heller något
                  dyrt eller krångligt, utan går nästan lika snabbt och enkelt
                  som att titta på den här filmen om hur det funkar.
                </p>
                <p className="mt-4 flex flex-wrap items-center font-intermedium text-[18px] italic">
                  All livsplanering du behöver från{" "}
                  <span className="px-2 font-interblack text-[22px] text-[#5F3741] lg:text-[36px]">
                    {partner?.price ? partner.price : defaultPackagePrice}:-
                  </span>
                  {partner?.price && `(Ordinarie pris 2999:-)`}
                </p>
              </div>
            </div>
          </div>
          <div className="mx-auto px-5 pt-16 pb-28 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-36 lg:pt-16 xl:max-w-[1360px]">
            <div className="mx-auto mb-10 flex max-w-[1100px] flex-col items-center justify-around gap-10 lg:flex-row lg:items-start">
              {steps.map((step, index) => (
                <div
                  key={step.title}
                  className="flex shrink-0 grow basis-0 flex-col items-center justify-center gap-5"
                >
                  <div className="flex h-[152px] w-[152px] items-center justify-center rounded-full bg-white">
                    <img
                      src={step.icon.href}
                      className="w-[50px]"
                      loading="lazy"
                      alt="Fenix"
                    />
                  </div>
                  <div className="flex max-w-[310px] flex-col text-center text-dark-blue">
                    <p className="mb-4 font-intermedium text-base uppercase tracking-[0.25em]">
                      Steg {index + 1}
                    </p>
                    <p className="font-internormal text-[13px] leading-[15px] ">
                      {step.title}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              {renderLoginButton(
                ` !bg-deep-blue !text-white hover:!border-[#6F86A0] hover:!bg-[#6F86A0]`,
                `#fff`
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="relative w-full bg-deep-blue text-white">
            <div className="mx-auto px-5 pt-16 pb-20 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-24 lg:pt-24 xl:max-w-[1360px]">
              <div className="quote-block relative max-w-[970px] rounded-[3px] pt-10 pb-8 lg:pb-12 lg:pt-20">
                <span className="quote-icon absolute -left-0.5 top-5 opacity-40 lg:-left-3 lg:top-10">
                  {getQuoteIcon(
                    "#CECABA",
                    isDesktop ? "91" : "48px",
                    isDesktop ? "76" : "40px"
                  )}
                </span>
                <div className="z-1 relative font-interlight text-[24px] italic leading-[29px] lg:text-[42px] lg:leading-[50.4px]">
                  Jag är mycket nöjd med Fenix Familys tjänst. Du får hjälp
                  snabbt. Det är lätt att hitta den information du letar efter.
                  Du känner dig trygg hela vägen."
                  <div className="mt-10 flex flex-col items-start lg:flex-row lg:items-end">
                    {renderLoginButton(` py-3 not-italic `, `#CECABA`)}
                    <div className="-ml-9 mt-12 lg:ml-0 lg:mt-0">
                      <TrustBox
                        template={"5419b732fbfb950b10de65e5"}
                        height={"24px"}
                        theme={"dark"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative w-full bg-white text-deep-blue">
            <div className="mx-auto px-5 pt-12 pb-16 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-60 lg:pt-36 xl:max-w-[1360px]">
              <AdvisorToggleSection dynamicNumber={true} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ConversationEmbedded
        showInline={false}
        showModalOverride={showConversationModal}
      />
    </ServiceSectionProvider>
  );
}
