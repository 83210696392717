import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { categories } from "./assets/data/datalist";
import ErrorBoundary from "./components/ErrorBoundary";
import GdprRedirect from "./components/GdprRedirect";
import Layout from "./components/Layout";
import LayoutPartner from "./components/LayoutPartner";
import LayoutPublic from "./components/LayoutPublic";
import LayoutPublicChat from "./components/LayoutPublicChat";
import { useUserContext } from "./context/UserContext";
import AboutPage from "./pages/AboutPage";
import AffiliatePage from "./pages/AffiliatePage";
import ArtiklarPage from "./pages/artiklar/ArtiklarPage";
import CategoryPage from "./pages/artiklar/CategoryPage";
import SubCategoryPage from "./pages/artiklar/SubcategoryPage";
import ContractPage from "./pages/ContractPage";
import ConversationPage from "./pages/ConversationPage";
import DebugPage from "./pages/DebugPage";
import FaqPage from "./pages/FaqPage";
import HomePage from "./pages/HomePage";
import IndexPage from "./pages/IndexPage";
import LoginPage from "./pages/LoginPage";
import NoPage from "./pages/NoPage";
import PaymentPage from "./pages/PaymentPage";
import Post1 from "./pages/Post1";
import Post2 from "./pages/Post2";
import Post3 from "./pages/Post3";
import ProductPage from "./pages/ProductPage";
import ProfilePage from "./pages/ProfilePage";
import RecommendationPage from "./pages/RecommendationPage";
import SinglePostSeo from "./pages/SinglePostSeo";

export default function App() {
  const { user, populateFromStorage, saveToStorage } = useUserContext();

  useEffect(() => {
    populateFromStorage();
  }, []);

  useEffect(() => {
    saveToStorage();
  }, [user]);

  const routeCategories = categories.map(({ title, slug }, key) => {
    return (
      <Route
        path={`/artiklar/${slug}/`}
        element={
          <CategoryPage
            title={`${title} - Fenix Family AB`}
            slug={slug}
            categoryTitle={title}
          />
        }
        key={key}
      />
    );
  });

  const routeSubCategories = categories.map(
    ({ title, slug, subcategories }, key) => {
      if (subcategories) {
        return subcategories.map((subcategory, key) => (
          <Route
            path={`/artiklar/${slug}/${subcategory.slug}`}
            element={
              <SubCategoryPage
                title={`${subcategory.title} - Fenix Family AB`}
                slug={subcategory.slug}
                subcategoryTitle={subcategory.title}
                categorySlug={slug}
                categoryTitle={title}
              />
            }
            key={key}
          />
        ));
      }
    }
  );

  return (
    <ErrorBoundary>
      <Routes>
        <Route element={<LayoutPublic />}>
          <Route
            path="/"
            element={<HomePage title="Fenix Family Livsplanering - Fenix" />}
          />
          <Route
            path="/faq/"
            element={<FaqPage title="FAQ - Fenix Family AB" />}
          />
          <Route
            path="/om-oss/"
            element={<AboutPage title="Om Oss - Fenix Family AB" />}
          />
          <Route
            path="/artiklar/"
            element={<ArtiklarPage title="Artiklar - Fenix Family AB" />}
          ></Route>
          {routeCategories}
          {routeSubCategories}
          <Route
            path="/artiklar/livsplanering/fullmakt/"
            element={<Post1 title="Fullmakt - Fenix Family AB" />}
          />
          <Route
            path="/artiklar/arv/arvsratt/"
            element={<Post2 title="Arv - Fenix Family AB" />}
          />
          <Route
            path="/artiklar/livsplanering/testamente/skriva-testamente/"
            element={<Post3 title="Skriva testamente - Fenix Family AB" />}
          />
          {/*<Route*/}
          {/*  path="/artiklar/seo/:id"*/}
          {/*  element={<SinglePostSeo title="Single post SEO - Fenix" />}*/}
          {/*/>*/}
        </Route>
        <Route element={<LayoutPublicChat />}>
          <Route
            path="/livsplanering/"
            element={<ProductPage title="Livsplanering - Fenix" />}
          />
        </Route>
        <Route element={<LayoutPartner />}>
          <Route path="/partner/:pt?/?" element={<AffiliatePage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/vault" element={<ProfilePage />} />
          <Route path="/debug" element={<DebugPage />} />
          <Route path="/conversation" element={<ConversationPage />} />
          <Route path="/recommendation" element={<RecommendationPage />} />
          <Route path="/payment/:data" element={<PaymentPage />} />
          <Route path="contract/:contractQuery" element={<ContractPage />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="/gdpr" element={<GdprRedirect />} />
      </Routes>
    </ErrorBoundary>
  );
}
