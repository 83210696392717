import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Sticky from "react-stickynode";

import { articles, faqBlock, steps } from "../assets/data/datalist";
import Accordion from "../components/Accordion";
import QuoteArticle from "../components/articles/QuoteArticle";
import TableArticle from "../components/articles/TableArticle";
import TipsArticle from "../components/articles/TipsArticle";
import TrustboxArticle from "../components/articles/TrustboxArticle";
import Footer from "../components/Footer";
import HeadMenu from "../components/HeadMenu";
import ArticlesSlider from "../components/slider/ArticlesSlider";
import VideoItem from "../components/VideoItem";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { useMediaQuery } from "../services/hooks/useMediaQuery";
import {
  getBankIdIcon,
  getButtonArrow,
  getEmail,
  getLink,
  getViewsIcon,
} from "../services/icons";
import { useScrollPosition } from "../services/utils";

type ArticleType = {
  id: number;
  date: string;
  views: string;
  hero: URL;
  thumb: URL;
  authorImage?: URL;
  name: string;
  title: string;
  link: string;
  categoryFirstLevel: string;
  categoryFirstLevelLink: string;
  text: string;
};

export const article: ArticleType[] = [
  {
    id: 1,
    date: "18 augusti 2023",
    views: "896",
    hero: new URL(
      "../assets/images/article/hero_3_1600x960.jpg",
      import.meta.url
    ),
    thumb: new URL(
      "../assets/images/article/thumb/hero_3_310x220.jpg",
      import.meta.url
    ),
    authorImage: new URL("../assets/images/advisor.png", import.meta.url),
    name: "Henning Wijkmark",
    title: "Skriva testamente",
    link: "testamente/skriva-testamente",
    categoryFirstLevel: "Livsplanering",
    categoryFirstLevelLink: "livsplanering",
    text: 'Den svenska arvsrätten styrs av Ärvdabalken. Det är den lag som styr fördelningen av tillgångar efter en persons bortgång. Att förstå lagarna och logiken i den är grundläggande för att du ska kunna var säker på att din egendom fördelas som du vill efter din bortgång. Den här artikeln syftar till att ge en översikt över svensk arvsrätt, bland annat dess historiska bakgrund, införandet av Ärvdabalken, vem som ärver, vad som händer om du inte är gift med din partner, hur barn ärver, arvsklasser, skillnader från andra större västländer, skatter, förskott på arv, vad man ska tänka på vid handläggning av ärenden som påverkas av arvsrätten, en förklaring av termerna "laglott" och "arvslott" och lite till. Allt får inte plats i en artikel (det finns en anledning till att lagboken är tjock) så om du behöver ytterligare vägledning är du varmt välkommen att kontakta oss på Fenix Family.',
  },
];

export default function Post3({
  title,
  link,
}: {
  title?: string;
  link?: string;
}) {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }
  const { id } = useParams<{ id: string }>();
  const [isHover, setIsHover] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(true);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [activeSection, setActiveSection] = useState(null);
  const [activeTitle, setActiveTitle] = useState(
    "Livsplanera med ett testamente"
  );
  const observer = useRef(null);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    document.title = title!;
    // create new instance and pass a callback function
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find(
          (entry) => entry.isIntersecting
        )?.target;
        // Update state with the visible section ID
        if (visibleSection) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setActiveSection(visibleSection.id);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setActiveTitle(visibleSection.getAttribute("data-title"));
        }
      },
      {
        threshold: isDesktop ? 0.25 : 0,
      }
    );

    // Get custom attribute data-section from all sections
    const sections = document.querySelectorAll("[data-section]");

    sections.forEach((section) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      observer.current.observe(section);
    });
    // Cleanup function to remove observer
    return () => {
      sections.forEach((section) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        observer.current.unobserve(section);
      });
    };
  }, []);

  function handleClick() {
    setOpenSidebar(!openSidebar);
  }

  const post = articles.filter((oneItem) => oneItem.link == id);

  const renderLoginButton = (buttonClass: string, iconColor: string) => {
    return (
      <Link
        to="/login"
        className={
          buttonClass +
          ` inline-flex items-center gap-2 rounded border-[1px] border-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] lg:gap-3 lg:self-end`
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon("#fff", "20", "100%")
          : getBankIdIcon(iconColor, "20", "100%")}
        Logga in
      </Link>
    );
  };

  const navigationList = () => {
    return (
      <ul className="relative inline-flex list-none flex-col items-start justify-start gap-6 pl-6 pr-5 before:absolute before:top-0 before:left-[5px] before:h-full before:w-[1px] before:bg-[#CECABA] before:content-['']">
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest  before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "livsplanera-med-ett-testamente"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
            to="#livsplanera-med-ett-testamente"
          >
            Livsplanera med ett testamente
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "section_1_1"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#section_1_1"
          >
            Sammanfattning av testamenteskrivning
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "section_1_2"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#section_1_2"
          >
            Digitala tillgångar och närvaro på nätet
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "section_1_3"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#section_1_3"
          >
            Arvtagare eller tillgångar utomlands
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "steps"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#steps"
          >
            Varför Fenix livsplanering?
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "faq"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#faq"
          >
            FAQ
          </HashLink>
        </li>
      </ul>
    );
  };

  const articlesFirstLevel = articles.filter((object) => {
    return (
      object.categoryFirstLevel !== null &&
      object.categorySecondLevel === null &&
      object.link !== article[0].link
    );
  });

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-white",
            "fixed z-1000 w-full transition-colors"
          )}
        >
          <HeadMenu
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={scrollPosition > 84 ? "#0C2632" : "#0C2632"}
          />
        </div>
        <div className="hero-article relative w-full overflow-hidden text-deep-blue">
          <img
            src={article[0].hero.href}
            alt={article[0].title}
            className="absolute left-0 top-0 z-10 h-full w-full object-cover"
            loading="lazy"
          />
          <div className="absolute left-0 top-0 z-20 h-full w-full bg-[#0C2632]/10" />
          <div className="z-1 relative z-30 mx-auto px-5 pb-6 pt-24 sm:max-w-[640px] md:max-w-[768px] lg:h-[735px] lg:max-w-[1024px] lg:pb-16 lg:pt-24 xl:max-w-[1360px]">
            <div className="hero-article flex h-full max-w-[750px] flex-col justify-between">
              <ul className="float-left inline space-x-2 text-[11px] capitalize text-white lg:flex">
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/"}>hem</Link>
                </li>
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/artiklar/"}>artiklar</Link>
                </li>
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/artiklar/livsplanering/"}>Livsplanering</Link>
                </li>
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/artiklar/livsplanering/testamente/"}>
                    Testamente
                  </Link>
                </li>
                <li className="pointer-events-none inline">
                  <Link to={`/artiklar/livsplanering/${article[0].link}`}>
                    Skriva testamente
                  </Link>
                </li>
              </ul>
              <div className="article-heading mt-28 lg:mt-0">
                <p className="text-xs font-medium uppercase tracking-[.25em] text-white">
                  {article[0].date}
                </p>
                <h1 className="mb-2 font-interblack text-[36px] italic leading-[2.7rem] text-white lg:mt-20 lg:text-[64px] xl:mt-5 xl:leading-[4.4rem]">
                  {article[0].title}
                </h1>
                <div className="flex text-[13px] text-white">
                  av {article[0].name}
                  <p className="flex items-center pl-5 text-white">
                    {getViewsIcon("#E8E3D2")}
                    <span className="pl-2">{article[0].views}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="article relative w-full overflow-hidden">
          <div className="relative mx-auto flex flex-col px-5 pt-0 pb-16 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:flex-row lg:pt-20 xl:max-w-[1360px]">
            <aside className="lg:rounded-b-0 absolute left-0 top-0 flex w-full lg:relative lg:max-w-[325px]">
              {isDesktop ? (
                <Sticky
                  top="#header"
                  bottomBoundary="#content"
                  activeClass="active w-full"
                  innerZ={999}
                >
                  <div className="sidebar self-start overflow-hidden rounded-[8px] bg-[#E8E3D2]">
                    <div className="inline-flex w-full items-start justify-start gap-3 bg-[#E8E3D2] px-6 py-10 lg:max-w-[324px]">
                      {navigationList()}
                    </div>
                  </div>
                </Sticky>
              ) : (
                <Sticky top="#header" activeClass="active w-full" innerZ={999}>
                  <div
                    className={
                      !openSidebar
                        ? "sidebar self-start rounded-b-[8px] bg-[#E8E3D2]"
                        : "sidebar h-[76px] self-start overflow-hidden rounded-b-[8px] bg-[#E8E3D2]"
                    }
                  >
                    <button
                      onClick={handleClick}
                      className={`relative h-[76px] w-full pl-5 pr-7 text-left font-internormal text-xs font-medium uppercase tracking-[0.2em] after:absolute after:right-5 after:top-7 after:h-[10px] after:w-[10px]  after:transform after:border-b after:border-r after:border-black/70 lg:hidden ${
                        openSidebar ? " after:rotate-45" : "after:rotate-225"
                      }`}
                    >
                      {activeTitle}
                    </button>
                    <div className="inline-flex w-full items-start justify-start gap-3 bg-[#E8E3D2] px-6 pt-3 pb-10">
                      {navigationList()}
                    </div>
                  </div>
                </Sticky>
              )}
            </aside>
            <article
              id="content"
              className="article-content w-full pt-24 lg:pt-0"
            >
              <div className="max-w-[760px] text-deep-blue lg:ml-24">
                <div className="pb-10 lg:pb-0"></div>
                <div
                  id="livsplanera-med-ett-testamente"
                  data-title="Livsplanera med ett testamente"
                  data-section
                  className="text-block scroll-my-40 pb-10"
                >
                  <h2 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                    Livsplanera med ett testamente
                  </h2>
                  <p className="whitespace-pre-wrap font-interlight text-base">
                    Att skriva ett testamente är ett viktigt steg i planeringen
                    av både nutid och framtid. Enkelt förklarat så ger ett
                    testamente dig möjligheten att fördela dina tillgångar som
                    du önskar efter din bortgång. Att ha detta klart kan ge dig
                    lugn och ro här och nu, samtidigt som det skapar rimliga,
                    gemensamma förväntningar och tydlighet för dina närmaste.
                    Det finns dock olika scenarier och utmaningar att ta hänsyn
                    till när man upprättar ett testamente, särskilt i komplexa
                    familjesituationer. Denna artikel ger dig en översikt över
                    viktiga faktorer att tänka på när man skriver ett
                    testamente, vare sig du skriver testamentet själv eller tar
                    hjälp av oss på Fenix Family eller någon annan juridisk
                    specialist. Vi tar bland annat upp scenarier med barn från
                    tidigare förhållanden, en nuvarande partner med barn från
                    tidigare förhållanden, begränsningarna i Ärvdabalken (namnet
                    på den svenska arvslagstiftningen) och kort om
                    livförsäkringens roll för att komplettera dina önskemål som
                    testamentet inte kan uppfylla.
                  </p>
                  <br />
                </div>
                <div className="text-block pb-10">
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Barn från tidigare förhållanden
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Om du idag har en partner och samtidigt bröstarvingar från
                      tidigare förhållanden (särkullbarn, särkullbarnbarn
                      o.s.v.) är det viktigt att du tar hänsyn till dem när du
                      skriver ditt testamente. Här är några viktiga punkter att
                      tänka på:
                    </p>
                    <br />
                    <ul className="marker:leading-1 list-disc pl-6 marker:text-[30px]">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px]">
                          Ange tydligt vilka som är förmånstagare.
                        </h4>{" "}
                        Ange tydligt namnen på dina bröstarvingar och deras
                        andel av arvet. Detta bidrar till att undvika oklarheter
                        och potentiella tvister.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Skydda bröstarvingars rättigheter.
                        </h4>{" "}
                        Den svenska lagen innehåller en rättighet för
                        bröstarvingar som säkerställer att de har rätt att
                        erhålla en skyddad andel, trots att ett eventuellt
                        testamente säger annorlunda. Detta kallas laglott. Vill
                        du fördela dina tillgångar på ett sätt där eventuella
                        laglotter riskerar att inskränkas, bör du rådfråga en
                        juridisk expert för att förstå konsekvenserna.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Särskild förvaltare.
                        </h4>{" "}
                        Om personer som du anger i testamentet är minderåriga
                        kan du utse en särskild förvaltare som förvaltar deras
                        arv tills de blir vuxna.
                      </li>
                    </ul>
                    {/*<h4 className="mb-0 font-intermedium text-[18px] ">*/}
                    {/*  Bankärenden.*/}
                    {/*</h4>*/}
                    {/*<p className="whitespace-pre-wrap font-interlight text-base font-semibold">*/}
                    {/*  Om du har svårt att hantera dina bankaffärer kan en fullmakt ge en person befogenhet att agera som ditt ombud. Det kan inkludera att sätta in eller ta ut pengar, betala räkningar eller hantera investeringar.*/}
                    {/*</p>*/}
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Partner med barn från tidigare förhållanden
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Moderna familjer med bonusbarn och barn från tidigare
                      förhållanden kan innebära utmaningar när det gäller
                      arvsplanering. Här är några saker som kan vara bra att
                      veta om:
                    </p>
                    <br />
                    <ul className="marker:leading-1 list-disc pl-6 marker:text-[30px]">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Tänk på alla.
                        </h4>{" "}
                        Fundera på hur du kan och hur du vill tillgodose både
                        din nya partner, dina barn och dina bonusbarn. Den
                        skyddade andelen (laglotten) för bröstarvingar, och
                        rättigheterna för en efterlevande make eller maka, bör
                        tas i beaktande.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Öppen kommunikation.
                        </h4>{" "}
                        Ett bra sätt att förebygga framtida konflikter är att
                        diskutera dina tankar och önskemål med din partner och
                        se till att hen förstår hur tillgångarna ska fördelas.
                        Öppenhet och gemensamma förväntningar är viktigt för att
                        undvika problem.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Sparande och försäkringar.
                        </h4>{" "}
                        För att komplettera ditt testamente är det bra om du
                        undersöker möjligheten att upprätta ett sparande eller
                        en försäkring för att hjälpa din partner ekonomiskt
                        under hens livstid. Sparande och livförsäkringar kan
                        även förenkla arvsprocessen för din partner om du har
                        bröstarvingar som ska få ut hela eller delar av sitt arv
                        i samband med din bortgång.
                      </li>
                    </ul>
                    {/*<h4 className="mb-0 font-intermedium text-[18px] ">*/}
                    {/*  Bankärenden.*/}
                    {/*</h4>*/}
                    {/*<p className="whitespace-pre-wrap font-interlight text-base font-semibold">*/}
                    {/*  Om du har svårt att hantera dina bankaffärer kan en fullmakt ge en person befogenhet att agera som ditt ombud. Det kan inkludera att sätta in eller ta ut pengar, betala räkningar eller hantera investeringar.*/}
                    {/*</p>*/}
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      BRA ATT VETA OM BEGRÄNSNINGAR I SVENSKA ARVSLAGAR
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Ärvdabalken, som den svenska arvslagen heter, reglerar
                      formkraven och begränsningarna för testamenten. Här är
                      några viktiga saker att tänka på:
                    </p>
                    <br />
                    <ul className="marker:leading-1 list-disc pl-6 marker:text-[30px]">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Arvslott och laglott.
                        </h4>{" "}
                        Lagen är skriven så att efterlevande makar i första hand
                        ärver den avlidne maken, med undantag för särkullbarn
                        eller deras avkommor som får ut hela sina arv direkt -
                        arvslott. Bröstarvingar har alltid rätt att ärva minst
                        halva arvslotten - laglott.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Begränsning av arvlöshet.
                        </h4>{" "}
                        Även om du har frihet att bestämma hur dina tillgångar
                        ska fördelas finns det begränsningar när det gäller att
                        göra nära släktingar arvlösa. Fråga gärna oss på Fenix
                        Family om råd utifrån din specifika situation.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Formkrav för testamenten.
                        </h4>{" "}
                        När du ska skriva ditt testamente är det bra att ta
                        hjälp av en jurist för råd. Detta då det finns flera
                        formkrav som måste uppfyllas för att testamentet ska
                        vara giltigt. Bland annat behöver du två oberoende
                        vittnen och du måste vara vid ditt fulla förstånd.
                      </li>
                    </ul>
                    {/*<h4 className="mb-0 font-intermedium text-[18px] ">*/}
                    {/*  Bankärenden.*/}
                    {/*</h4>*/}
                    {/*<p className="whitespace-pre-wrap font-interlight text-base font-semibold">*/}
                    {/*  Om du har svårt att hantera dina bankaffärer kan en fullmakt ge en person befogenhet att agera som ditt ombud. Det kan inkludera att sätta in eller ta ut pengar, betala räkningar eller hantera investeringar.*/}
                    {/*</p>*/}
                  </div>
                </div>
                <div
                  data-section
                  data-title="Sammanfattning av testamenteskrivning"
                  id="section_1_1"
                  className="headline-block scroll-my-40 pb-10 lg:scroll-my-20"
                >
                  <div className="headline-block">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Sammanfattning av testamenteskrivning
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Att skriva ett testamente är ett viktigt steg i din
                      livsplanering för att vara säker på att dina tillgångar
                      fördelas som du vill. Det blir ännu viktigare att noga
                      överväga olika scenarier och utmaningar när du har barn
                      från tidigare förhållanden eller en nuvarande partner med
                      barn från tidigare förhållanden. Att förstå svensk
                      arvsrätts möjligheter och begränsningar är inte lätt och
                      att skriva testamente själv kan vara knepigt. Tveka inte
                      att be oss om hjälp för att navigera rätt i den svenska
                      arvsrätten utifrån din specifika livssituation. Börja med
                      att göra en livsplanering på{" "}
                      <a href="https://fenixfamily.se/">fenixfamily.se</a>. Det
                      brukar räcka långt!
                      <br />
                      <br />
                      Att inkludera en livförsäkring när du ska skriva ditt
                      testamente kan ge ett ovärderligt skydd för din
                      efterlevande familj, särskilt när det gäller bostaden.
                      Livförsäkringen kan hjälpa till att täcka bolånekostnader,
                      ge finansiell stabilitet och säkerställa en smidig
                      övergång för dina nära och kära efter din bortgång. Genom
                      att ta hänsyn till dessa scenarier, utmaningar och
                      möjligheter kan du skapa en smart livsplanering som
                      skyddar alla inblandade parters intressen.
                    </p>
                    <br />
                  </div>
                </div>
                {/*<QuoteArticle />*/}
                <TrustboxArticle />
                <div className="mb-20"></div>
                <div
                  data-section
                  id="section_1_2"
                  data-title="Digitala tillgångar och närvaro på nätet"
                  className="text-block scroll-my-40 pb-10 lg:scroll-my-20"
                >
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Digitala tillgångar och närvaro på nätet
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      I den digitala värld vi lever i är det viktigt att ta
                      hänsyn till dina digitala tillgångar och din närvaro på
                      nätet när du ska skriva ditt testamente. Här är några
                      viktiga aspekter:
                      <br />
                      <br />
                    </p>
                    <br />
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Digitala tillgångar.
                        </h4>{" "}
                        Inkludera instruktioner för att ta itu med dina digitala
                        tillgångar, till exempel online-konton, kryptovalutor,
                        digitala foton och immateriella rättigheter. Ange hur du
                        vill att dessa tillgångar ska förvaltas eller överföras
                        efter din bortgång.{" "}
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Lösenord och åtkomst.
                        </h4>{" "}
                        Se till att dokumentera och lagra lösenord och
                        åtkomstinformation på ett säkert sätt. Överväg att
                        använda en lösenordshanterare eller andra säkra metoder
                        för att säkerställa att dina nära och kära kan komma åt
                        och hantera dina digitala tillgångar efter din bortgång.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Sociala medier och digitalt arv.
                        </h4>{" "}
                        Tänk på dina konton på sociala medier och din närvaro på
                        nätet. Du kanske vill ge instruktioner om hur du vill
                        att dina profiler ska hanteras efter din bortgång. Vissa
                        plattformar erbjuder alternativ för minnesmärkning eller
                        radering av konton på begäran.
                      </li>
                    </ul>
                    <br />
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Regelbunden översyn och uppdateringar
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      När du har skrivit ditt testamente är det viktigt att se
                      över och uppdatera det med jämna mellanrum. Livshändelser,
                      såsom äktenskap, skilsmässor, födslar, dödsfall eller
                      betydande förändringar i de ekonomiska förhållandena, kan
                      motivera revideringar av din arvsplan. Gör det till en
                      vana att se över ditt testamente med några års mellanrum
                      eller när en större livsförändring inträffar, för att vara
                      säker på att det stämmer med dina önskemål.
                      <br />
                    </p>
                    <br />
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Professionell testamentsexekutor
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      För att vara helt säker på att dina önskemål i testamentet
                      uppfylls, är det klokt att ha en professionell exekutor -
                      det vill säga en neutral jurist som verkställer det som är
                      skrivet i testamentet. Detta gör att dina önskemål
                      genomförs effektivt och i enlighet med lagen. En
                      professionell testamentsexekutor kan bidra med expertis
                      och opartiskhet, och minska bördan för dina nära och kära
                      under en känslomässig tid. Vi på Fenix Family har stor
                      erfarenhet av detta och hjälper gärna till att verkställa
                      ditt testamente.
                      <br />
                    </p>
                    <br />
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Att skriva testamente som företagare
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Om du är företagare finns det särskilda saker att tänka på
                      när du skriver ditt testamente. Här är några viktiga
                      punkter:
                      <br />
                      <br />
                    </p>
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Utse din efterträdare.
                        </h4>{" "}
                        Om du har ett företag är det viktigt att du tar upp dess
                        framtid i ditt testamente. Fundera på vem du vill ska ta
                        över verksamheten eller om du föredrar att den säljs
                        eller upplöses. Rådgör med en affärsjurist eller
                        specialist för att utveckla en successionsplan.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Aktieägaravtal.
                        </h4>{" "}
                        Om ditt företag har flera aktieägare bör du granska
                        eventuella befintliga aktieägaravtal för att förstå
                        bestämmelserna om överföring eller arv av aktier. Se
                        till att ditt testamente överensstämmer med dessa avtal
                        och rådgör med kompetenta jurister för att reda ut
                        eventuella utmaningar.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Nyckelpersoner.
                        </h4>{" "}
                        Identifiera nyckelpersoner eller anställda som spelar en
                        avgörande roll för ditt företags verksamhet och
                        framgång. Överväg att inkludera bestämmelser för att
                        säkerställa deras fortsatta engagemang eller för att
                        säkerställa en smidig övergång i händelse av din
                        bortgång.
                      </li>
                    </ul>
                  </div>
                </div>
                {/*<TipsArticle />*/}
                <div className="last-articles relative mb-20 pt-10 before:absolute before:top-0 before:left-[-50rem] before:h-full before:w-[500%] before:bg-neutral-yellow before:content-['']">
                  <h4 className="relative mb-6 font-intermedium text-xs uppercase tracking-[0.2em] text-[#736F62]">
                    Har du även läst:
                  </h4>
                  <ArticlesSlider
                    articles={articlesFirstLevel.slice(0, 2)}
                    init={false}
                    quantity={2}
                  />
                </div>
                <div
                  data-section
                  id="section_1_3"
                  data-title="Arvtagare eller tillgångar utomlands"
                  className="text-block scroll-my-40 pb-10 lg:scroll-my-20"
                >
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Arvtagare eller tillgångar utomlands
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Om du har tillgångar eller förmånstagare i flera länder
                      kan det uppstå ytterligare överväganden. Här är några
                      punkter att vara medveten om:
                      <br />
                      <br />
                    </p>
                    <br />
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Dubbla medborgarskap och beskattning.
                        </h4>{" "}
                        Om du har dubbelt medborgarskap eller tillgångar i flera
                        jurisdiktioner är det viktigt att förstå de potentiella
                        skattekonsekvenser och juridiska komplikationer som kan
                        uppstå. Sök råd från experter med expertis inom
                        internationell förmögenhets- och fastighetsplanering för
                        att navigera bland dessa frågor.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Internationella arvslagar.
                        </h4>{" "}
                        Om du har tillgångar eller förmånstagare i olika länder
                        bör du bekanta dig med arvsrätten i dessa
                        jurisdiktioner. Olika länder kan ha olika bestämmelser
                        om testamenten, boutredning och arvsrätt. Överväg att
                        konsultera jurister i varje relevant jurisdiktion för
                        att säkerställa att ditt testamente är giltigt och
                        verkställbart. Du kan även från början skriva in i ditt
                        testamente vilket lands arvsrätt som ska tillämpas.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Samordning av testamenten.
                        </h4>{" "}
                        I de fall du har tillgångar eller förmånstagare i flera
                        länder kan det vara nödvändigt att samordna testamenten.
                        Det är viktigt att se till att de testamenten du har i
                        olika länder fungerar tillsammans och inte står i
                        konflikt med varandra. Sök vägledning från jurister med
                        erfarenhet av gränsöverskridande förmögenhets- och
                        fastighetsplanering för att säkerställa en
                        sammanhängande och effektiv plan.
                      </li>
                    </ul>
                    <br />
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Slutföra ditt testamente
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      När du har skrivit ditt testamente är det viktigt att se
                      till att det förvaras, övervakas och verkställs på rätt
                      sätt. Tänk på följande:
                      <br />
                      <br />
                    </p>
                    <br />
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Vittnen och underskrifter.
                        </h4>{" "}
                        I Sverige måste ett testamente undertecknas av testatorn
                        (den person som skriver testamentet) i närvaro av minst
                        två vittnen. Vittnena måste också underteckna
                        testamentet. Se till att dessa krav är uppfyllda för att
                        göra ditt testamente giltigt.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Säker förvaring.
                        </h4>{" "}
                        Förvara ditt originaltestamente på en säker plats, t.ex.
                        i ett bankfack eller hos din advokat. Informera dina
                        nära och kära och exekutorn om var din vilja finns och
                        ge dem nödvändiga åtkomstinstruktioner. På Fenix Family
                        förvaras ditt testamente både i ditt digitala kassaskåp
                        och i fysisk form på säker plats.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Övervakning.
                        </h4>{" "}
                        Det är även klokt att låta en neutral tredje part, gärna
                        en jurist, övervaka ditt testamente för att se till att
                        det hamnar i rätt händer när det ska verkställas.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Tydlig kommunikation.
                        </h4>{" "}
                        Kommunicera med dina nära och kära och de nyckelpersoner
                        som är involverade i din livsplanering, t.ex. din
                        testamentsexekutor eller advokat. Informera dem om
                        existensen och platsen för ditt testamente och andra
                        eventuella medskick till dina efterlevande som de
                        behöver vara medvetna om.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text-block pb-10">
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Testamente - ett viktigt steg i din livsplanering
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Att skriva ett testamente kräver genomtänkta överväganden
                      av olika scenarier, utmaningar och möjligheter. Kom ihåg
                      att även om denna artikel ger allmän vägledning är det
                      viktigt att rådgöra med en kvalificerad advokat eller
                      jurist för att få personlig rådgivning. På så vis kan du
                      få relevanta råd anpassade efter din livssituation och
                      dina specifika önskemål.
                      <br />
                      <br />
                      Hoppas att den här artikeln gav dig den kunskap du letade
                      efter! Har du frågor är det bara att kontakta oss.
                      <br />
                      <br />
                    </p>
                    <br />
                  </div>
                </div>
                {/*<QuoteArticle />*/}
                {/*<TableArticle />*/}
                <div
                  data-section
                  id="steps"
                  data-title="Varför Fenix livsplanering?"
                  className="step-block scroll-my-40 pb-10 lg:scroll-my-20"
                >
                  <div className="inline-flex flex-col items-center justify-start gap-10">
                    <h2 className="text-center font-internormal text-base font-semibold uppercase leading-normal tracking-[4px]">
                      Varför Fenix livsplanering?
                    </h2>
                    <div className="flex flex-col items-start justify-start gap-8 md:flex-row lg:inline-flex lg:gap-20">
                      {steps.map((step, index) => (
                        <div
                          key={index}
                          className="inline-flex flex-col items-center justify-start gap-6"
                        >
                          <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full bg-[#E8E3D2] text-center text-[28px] font-semibold uppercase lg:h-[118px] lg:w-[118px] lg:text-[42px]">
                            {index + 1}
                          </div>
                          <div className="max-w-[194px] text-center text-[13px] font-normal leading-none">
                            {step.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <VideoItem />
                <br />
                <br />
                <br />
                <TrustboxArticle />
              </div>
            </article>
          </div>
        </div>
        <div
          data-section
          id="faq"
          data-title="FAQ"
          className="relative w-full border-t border-[#CECABA] text-deep-blue lg:border-t-0"
        >
          <div className="mx-auto px-5 pt-28 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:border-t lg:border-[#CECABA] lg:pt-24 lg:pb-32 xl:max-w-[1360px]">
            <div className="mx-auto max-w-[980px]">
              <h2 className="text-center font-interblack text-[28px] italic leading-[2.7rem] text-deep-blue xl:text-[48px] xl:leading-[4.5rem]">
                FAQ
              </h2>
            </div>
            <div className="mx-auto mt-3 max-w-[985px] pt-5 pb-24">
              <Accordion items={faqBlock} />
            </div>
          </div>
        </div>
        <div className="relative w-full bg-[#F3F0E6] text-deep-blue">
          <div className="mx-auto px-5 pt-12 pb-12 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-9 xl:max-w-[1360px]">
            <h2 className="mb-6 pt-8 font-intermedium text-base uppercase tracking-[.25em] lg:mb-3">
              Populära artiklar
            </h2>
            <ArticlesSlider
              articles={articlesFirstLevel.slice(0, 4)}
              init={false}
              quantity={4}
            />
          </div>
        </div>
      </div>
      <Footer />
    </ServiceSectionProvider>
  );
}
