import React from "react";
import { Link } from "react-router-dom";

import { partners } from "../assets/data/datalist";
import { getChatIcon } from "../services/icons";
import AdvisorToggleSection from "./AdvisorToggleSection";
import ReviewsSlider from "./slider/ReviewsSlider";

const AdvisorsSection = ({
  buttonOnClick,
  title,
}: {
  title: string;
  buttonOnClick: () => void;
}) => {
  return (
    <div className="relative w-full bg-white text-deep-blue">
      <div className="mx-auto flex flex-col items-center px-5 pt-28 pb-16 sm:max-w-[640px] md:max-w-[700px] lg:max-w-[800px] lg:pb-10 lg:pt-24 xl:max-w-[800px]">
        <h2 className="text-center font-intermedium text-base font-semibold uppercase tracking-[0.25em]">
          {title}
        </h2>
        <div
          id="partners"
          className={`mt-7 flex flex-wrap items-center justify-center justify-items-center gap-3 lg:grid lg:grid-cols-3 lg:gap-8`}
        >
          {partners.map((partner, index) => (
            <img
              key={index}
              src={partner.logo.href}
              alt={partner.title}
              className="h-auto w-[30%] max-w-full lg:w-full"
              style={{ filter: "grayscale(100%)" }}
              loading="lazy"
            />
          ))}
        </div>
        <ReviewsSlider />
        <Link
          className="mt-8 flex min-w-[260px] justify-center space-x-3 rounded-[4px] border-[#0C2632] bg-deep-blue px-2 py-4 font-internormal text-[14px] font-semibold uppercase tracking-[0.12em] text-[#fff] hover:border-[#6F86A0] hover:bg-[#6F86A0] lg:gap-3 lg:space-x-1 lg:px-5 lg:py-[16px] lg:pb-4"
          to="#"
          onClick={() =>
            (window.location =
              "mailto:pre-need@fenixfamily.se?subject=Bli partner med Fenix Family&body=Hej,%0D%0A%0D%0AJag är intresserad av att veta mer om ett framtida partnerskap med Fenix Family och önskar att ni kontaktar mig på denna mailadress inom kort.%0D%0A%0D%0ATack på förhand!%0D%0A%0D%0AMed vänlig hälsning,")
          }
        >
          {getChatIcon("#FFFEFB", "18px", "18px")}{" "}
          <span>Bli vår nya partner</span>
        </Link>
      </div>
      <div
        id="vara-jurister"
        className="mx-auto px-5 pt-12 pb-16 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pb-10 lg:pt-24 xl:max-w-[1360px]"
      >
        <AdvisorToggleSection />
      </div>
    </div>
  );
};

export default AdvisorsSection;
