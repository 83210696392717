import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Sticky from "react-stickynode";

import { articles, faqBlock, steps } from "../assets/data/datalist";
import Accordion from "../components/Accordion";
import QuoteArticle from "../components/articles/QuoteArticle";
import TableArticle from "../components/articles/TableArticle";
import TipsArticle from "../components/articles/TipsArticle";
import TrustboxArticle from "../components/articles/TrustboxArticle";
import Footer from "../components/Footer";
import HeadMenu from "../components/HeadMenu";
import ArticlesSlider from "../components/slider/ArticlesSlider";
import VideoItem from "../components/VideoItem";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { useMediaQuery } from "../services/hooks/useMediaQuery";
import {
  getBankIdIcon,
  getButtonArrow,
  getEmail,
  getLink,
  getViewsIcon,
} from "../services/icons";
import { useScrollPosition } from "../services/utils";

type ArticleType = {
  id: number;
  date: string;
  views: string;
  hero: URL;
  thumb: URL;
  authorImage?: URL;
  name: string;
  title: string;
  link: string;
  categoryFirstLevel: string;
  categoryFirstLevelLink: string;
  text: string;
};

export const article: ArticleType[] = [
  {
    id: 1,
    date: "18 augusti 2023",
    views: "1012",
    hero: new URL(
      "../assets/images/article/hero_1_1600x960.jpg",
      import.meta.url
    ),
    thumb: new URL(
      "../assets/images/article/thumb/hero_1_310x220.jpg",
      import.meta.url
    ),
    authorImage: new URL("../assets/images/advisor.png", import.meta.url),
    name: "Henning Wijkmark",
    title: "Fullmakt",
    link: "fullmakt",
    categoryFirstLevel: "Livsplanering",
    categoryFirstLevelLink: "livsplanering",
    text: "En fullmakt är ett juridiskt dokument som ger en person (fullmaktsgivaren), befogenhet att agera å någon annans vägnar, (fullmaktstagaren). Det är ett kraftfullt verktyg som kan underlätta och effektivisera olika livssituationer och transaktioner. Den här artikeln syftar till att ge dig en översikt över vad en fullmakt är, vilket syfte den har, när den kan vara användbar, hur man skriver en fullmakt, vad man bör tänka på och en hel del annat som kan vara relevant.",
  },
];

export default function Post1({
  title,
  link,
}: {
  title?: string;
  link?: string;
}) {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }
  const { id } = useParams<{ id: string }>();
  const [isHover, setIsHover] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(true);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [activeSection, setActiveSection] = useState(null);
  const [activeTitle, setActiveTitle] = useState("Introduktion");
  const observer = useRef(null);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    document.title = title!;
    // create new instance and pass a callback function
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find(
          (entry) => entry.isIntersecting
        )?.target;
        // Update state with the visible section ID
        if (visibleSection) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setActiveSection(visibleSection.id);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setActiveTitle(visibleSection.getAttribute("data-title"));
        }
      },
      {
        threshold: isDesktop ? 0.25 : 0,
      }
    );

    // Get custom attribute data-section from all sections
    const sections = document.querySelectorAll("[data-section]");

    sections.forEach((section) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      observer.current.observe(section);
    });
    // Cleanup function to remove observer
    return () => {
      sections.forEach((section) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        observer.current.unobserve(section);
      });
    };
  }, []);

  function handleClick() {
    setOpenSidebar(!openSidebar);
  }

  const post = articles.filter((oneItem) => oneItem.link == id);

  const renderLoginButton = (buttonClass: string, iconColor: string) => {
    return (
      <Link
        to="/login"
        className={
          buttonClass +
          ` inline-flex items-center gap-2 rounded border-[1px] border-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] lg:gap-3 lg:self-end`
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon("#fff", "20", "100%")
          : getBankIdIcon(iconColor, "20", "100%")}
        Logga in
      </Link>
    );
  };

  const navigationList = () => {
    return (
      <ul className="relative inline-flex list-none flex-col items-start justify-start gap-6 pl-6 pr-5 before:absolute before:top-0 before:left-[5px] before:h-full before:w-[1px] before:bg-[#CECABA] before:content-['']">
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest  before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "intro"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
            to="#intro"
          >
            Introduktion
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "section_1_1"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#section_1_1"
          >
            Hur man skriver en fullmakt
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "section_1_2"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#section_1_2"
          >
            Framtidsfullmakter - så att livet kan rulla på som vanligt
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "section_1_3"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#section_1_3"
          >
            Viktigt att tänka på när du skriver en framtidsfullmakt
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "section_1_4"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#section_1_4"
          >
            Juridiska konsekvenser och skyldigheter
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "steps"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#steps"
          >
            Varför Fenix livsplanering?
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "faq"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#faq"
          >
            FAQ
          </HashLink>
        </li>
      </ul>
    );
  };

  const articlesFirstLevel = articles.filter((object) => {
    return (
      object.categoryFirstLevel !== null &&
      object.categorySecondLevel === null &&
      object.link !== article[0].link
    );
  });

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-white",
            "fixed z-1000 w-full transition-colors"
          )}
        >
          <HeadMenu
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={scrollPosition > 84 ? "#0C2632" : "#0C2632"}
          />
        </div>
        <div className="hero-article relative w-full overflow-hidden text-deep-blue">
          <img
            src={article[0].hero.href}
            alt={article[0].title}
            className="absolute left-0 top-0 z-10 h-full w-full object-cover"
            loading="lazy"
          />
          <div className="absolute left-0 top-0 z-20 h-full w-full bg-[#0C2632]/10" />
          <div className="z-1 relative z-30 mx-auto px-5 pb-6 pt-24 sm:max-w-[640px] md:max-w-[768px] lg:h-[735px] lg:max-w-[1024px] lg:pb-16 lg:pt-24 xl:max-w-[1360px]">
            <div className="hero-article flex h-full max-w-[750px] flex-col justify-between">
              <ul className="float-left inline space-x-2 text-[11px] capitalize text-white lg:flex">
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/"}>hem</Link>
                </li>
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/artiklar/"}>artiklar</Link>
                </li>
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/artiklar/livsplanering/"}>Livsplanering</Link>
                </li>
                <li className="pointer-events-none inline">
                  <Link to={`/artiklar/livsplanering/${article[0].link}/`}>
                    Fullmakt
                  </Link>
                </li>
              </ul>
              <div className="article-heading mt-28 lg:mt-0">
                <p className="text-xs font-medium uppercase tracking-[.25em] text-white">
                  {article[0].date}
                </p>
                <h1 className="mb-2 font-interblack text-[36px] italic leading-[2.7rem] text-white lg:mt-20 lg:text-[64px] xl:mt-5 xl:leading-[4.4rem]">
                  {article[0].title}
                </h1>
                <div className="flex text-[13px] text-white">
                  av {article[0].name}
                  <p className="flex items-center pl-5 text-white">
                    {getViewsIcon("#E8E3D2")}
                    <span className="pl-2">{article[0].views}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="article relative w-full overflow-hidden">
          <div className="relative mx-auto flex flex-col px-5 pt-0 pb-16 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:flex-row lg:pt-20 xl:max-w-[1360px]">
            <aside className="lg:rounded-b-0 absolute left-0 top-0 flex w-full lg:relative lg:max-w-[325px]">
              {isDesktop ? (
                <Sticky
                  top="#header"
                  bottomBoundary="#content"
                  activeClass="active w-full"
                  innerZ={999}
                >
                  <div className="sidebar self-start overflow-hidden rounded-[8px] bg-[#E8E3D2]">
                    <div className="inline-flex w-full items-start justify-start gap-3 bg-[#E8E3D2] px-6 py-10 lg:max-w-[324px]">
                      {navigationList()}
                    </div>
                  </div>
                </Sticky>
              ) : (
                <Sticky top="#header" activeClass="active w-full" innerZ={999}>
                  <div
                    className={
                      !openSidebar
                        ? "sidebar self-start rounded-b-[8px] bg-[#E8E3D2]"
                        : "sidebar h-[76px] self-start overflow-hidden rounded-b-[8px] bg-[#E8E3D2]"
                    }
                  >
                    <button
                      onClick={handleClick}
                      className={`relative h-[76px] w-full pl-5 pr-7 text-left font-internormal text-xs font-medium uppercase tracking-[0.2em] after:absolute after:right-5 after:top-7 after:h-[10px] after:w-[10px]  after:transform after:border-b after:border-r after:border-black/70 lg:hidden ${
                        openSidebar ? " after:rotate-45" : "after:rotate-225"
                      }`}
                    >
                      {activeTitle}
                    </button>
                    <div className="inline-flex w-full items-start justify-start gap-3 bg-[#E8E3D2] px-6 pt-3 pb-10">
                      {navigationList()}
                    </div>
                  </div>
                </Sticky>
              )}
            </aside>
            <article
              id="content"
              className="article-content w-full pt-28 lg:pt-0"
            >
              <div className="max-w-[760px] text-deep-blue lg:ml-24">
                <div className="pb-10 lg:pb-0"></div>
                <div
                  id="intro"
                  data-title="Introduktion"
                  data-section
                  className="text-block scroll-my-40 pb-10"
                >
                  <h2 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                    Introduktion
                  </h2>
                  <p className="whitespace-pre-wrap font-interlight text-base">
                    En fullmakt är ett juridiskt dokument som ger en person (
                    <b>fullmaktsgivaren</b>), befogenhet att agera å någon
                    annans vägnar, (<b>fullmaktstagaren</b>). Det är ett
                    kraftfullt verktyg som kan underlätta och effektivisera
                    olika livssituationer och transaktioner. Den här artikeln
                    syftar till att ge dig en översikt över vad en fullmakt är,
                    vilket syfte den har, när den kan vara användbar, hur man
                    skriver en fullmakt, vad man bör tänka på och en hel del
                    annat som kan vara relevant.
                  </p>
                  <br />
                </div>
                <div className="text-block pb-10">
                  <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                    Vad är en fullmakt?
                  </h3>
                  <p className="whitespace-pre-wrap font-interlight text-base">
                    En fullmakt är ett skriftligt dokument där en person ger en
                    annan person rätten att agera å sina vägnar, till exempel
                    utföra bankärenden, rösta i bostadsrättsföreningens styrelse
                    eller teckna avtal. Fullmakten kan vara bred och omfatta
                    olika befogenheter och ansvarsområden eller vara mer
                    specifik och begränsad till en viss uppgift eller
                    tidsperiod. Fullmaktsgivaren behåller normalt sin egen
                    juridiska kapacitet och kan fortsätta att agera
                    självständigt. Fullmaktstagaren agerar endast i den
                    utsträckning som anges i fullmakten och ska alltid handla
                    enligt fullmaktsgivarens intressen och instruktioner.
                  </p>
                  <br />
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Syftet med en fullmakt
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      En fullmakt kan ha flera syften och användningsområden.
                      Här är några vanliga situationer där en fullmakt kan vara
                      användbar:
                    </p>
                    <br />
                    <ul className="marker:leading-1 list-disc pl-6 marker:text-[30px]">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Sjukdom eller oförmåga.
                        </h4>{" "}
                        Om du blir sjuk eller oförmögen att hantera dina egna
                        angelägenheter kan en fullmakt tillåta en person du
                        litar på att agera å dina vägnar. Det kan inkludera att
                        fatta beslut om hälso- och sjukvård, ekonomiska
                        transaktioner och andra angelägenheter.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Resor.
                        </h4>{" "}
                        Om du ska vara borta under en längre tid, särskilt
                        utomlands, kan en fullmakt ge någon annan möjlighet att
                        hantera dina angelägenheter under din frånvaro. Det kan
                        vara att sälja egendom, betala räkningar eller företräda
                        dig i juridiska frågor.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Affärsangelägenheter.
                        </h4>{" "}
                        I affärssammanhang kan en fullmakt ge en person rätten
                        att agera på företagets eller organisationens vägnar.
                        Det kan innefatta att ingå avtal, hantera
                        banktransaktioner eller representera företaget i
                        rättsliga ärenden.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Fastighetsaffärer.
                        </h4>{" "}
                        Vid köp eller försäljning av fastigheter kan en fullmakt
                        ge en betrodd person befogenhet att agera på dina vägnar
                        vid förhandlingar, signering av dokument och överföring
                        av egendom.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Bankärenden.
                        </h4>{" "}
                        Om du har svårt att hantera dina bankaffärer kan en
                        fullmakt ge en person befogenhet att agera som ditt
                        ombud. Det kan inkludera att sätta in eller ta ut
                        pengar, betala räkningar eller hantera investeringar.
                      </li>
                    </ul>
                    {/*<h4 className="mb-0 font-intermedium text-[18px] ">*/}
                    {/*  Bankärenden.*/}
                    {/*</h4>*/}
                    {/*<p className="whitespace-pre-wrap font-interlight text-base font-semibold">*/}
                    {/*  Om du har svårt att hantera dina bankaffärer kan en fullmakt ge en person befogenhet att agera som ditt ombud. Det kan inkludera att sätta in eller ta ut pengar, betala räkningar eller hantera investeringar.*/}
                    {/*</p>*/}
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Att tänka på angående fullmakter
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      När det gäller fullmakter finns det några viktiga saker
                      att komma ihåg:
                    </p>
                    <br />
                    <ul className="marker:leading-1 list-disc pl-6 marker:text-[30px]">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Välj någon du litar på.
                        </h4>{" "}
                        Välj noggrant den person som ska vara din
                        fullmaktstagare. Det är viktigt att välja någon som är
                        kompetent, pålitlig och handlingskraftig nog att agera i
                        enlighet med dina intressen och instruktioner.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Var tydlig och precis.
                        </h4>{" "}
                        Var tydlig och specifik i fullmakten om vilka
                        befogenheter fullmaktstagaren har, och under vilka
                        omständigheter
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Undertecknande och vittnen.
                        </h4>{" "}
                        En vanlig fullmakt behöver bara undertecknas av
                        fullmaktsgivaren medan en <b>framtidsfullmakt</b> måste
                        undertecknas av fullmaktsgivaren i närvaro av två
                        oberoende vittnen som också undertecknar fullmakten.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Glöm inte att uppdatera fullmakten.
                        </h4>{" "}
                        Om din livssituation eller dina behov förändras är det
                        viktigt att uppdatera fullmakten så att den fortsätter
                        att återspegla dina intentioner.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  data-section
                  id="section_1_1"
                  data-title="Hur man skriver en fullmakt"
                  className="text-block scroll-mt-40 lg:scroll-mt-20"
                >
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Hur man skriver en fullmakt
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Att skriva en fullmakt behöver inte vara så svårt, följ
                      bara dessa enkla steg:
                    </p>
                    <br />
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Ge dokumentet en tydlig rubrik.
                        </h4>{" "}
                        I början av dokumentet ska du tydligt ange att det är en
                        fullmakt och att du ger en annan person befogenhet att
                        agera å dina vägnar.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Identifiera parterna.
                        </h4>{" "}
                        Ange både ditt eget (fullmaktsgivaren) och personen som
                        ska agera å dina vägnars (fullmaktstagaren) namn och
                        kontaktuppgifter.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Beskriv fullmaktstagarens uppdrag.
                        </h4>{" "}
                        Specificera tydligt vilka befogenheter, uppgifter och
                        ansvarsområden fullmaktstagaren har.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Var specifik och detaljerad.
                        </h4>{" "}
                        Ange detaljer om vilka specifika åtgärder eller beslut
                        som fullmaktstagaren kan ta. Detta kan omfatta
                        ekonomiska transaktioner, fastighetsaffärer eller hälso-
                        och sjukvårdsbeslut. Hellre för specifikt än för
                        generellt, tänk inte att fullmaktstagaren eller andra
                        personer ska förstå saker som inte står exakt utskrivet
                        i fullmakten.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Underteckna.
                        </h4>{" "}
                        En vanlig fullmakt behöver bara undertecknas av
                        fullmaktsgivaren medan en <b>framtidsfullmakt</b> måste
                        undertecknas av fullmaktsgivaren i närvaro av två
                        oberoende vittnen som också undertecknar fullmakten.
                      </li>
                    </ul>
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Ta hjälp av en juridisk expert
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Vid upprättande av en fullmakt kan det vara klokt att ta
                      hjälp av en jurist. De kan ge vägledning och säkerställa
                      att fullmakten uppfyller alla nödvändiga juridiska krav,
                      blir tydligt formulerat och är anpassad till din specifika
                      livssituation. Vi på Fenix Family eller någon annan
                      juridisk expert kan också hjälpa till med att säkerställa
                      att fullmakten är korrekt undertecknad och bevittnad.
                    </p>
                    <br />
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Sammanfattning
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      En fullmakt kan vara ett kraftfullt verktyg som kan
                      underlätta och effektivisera olika livssituationer och
                      transaktioner. Genom att ge en betrodd person befogenhet
                      att agera å dina vägnar kan du säkerställa att dina behov
                      hanteras i enlighet med dina intressen och instruktioner.
                      Det är viktigt att vara noggrann när du skriver en
                      fullmakt och att välja rätt person att agera som
                      fullmaktstagare. Genom att följa Fenix Familys
                      grundläggande instruktioner kan du själv skriva en både
                      tydlig och säker fullmakt.
                      <br />
                      <br />
                      Kom ihåg att denna artikel erbjuder allmän vägledning. För
                      att få vara säker på att det blir rätt kan du fråga oss på
                      Fenix Family om råd baserat på din livssituation och dina
                      behov.
                    </p>
                    <br />
                  </div>
                </div>
                {/*<QuoteArticle />*/}
                <TrustboxArticle />
                <div className="mb-16"></div>
                <div
                  data-section
                  id="section_1_2"
                  data-title="Framtidsfullmakter - så att livet kan rulla på som vanligt"
                  className="text-block scroll-my-40 pb-10 lg:scroll-my-20"
                >
                  <h2 className="mb-3 whitespace-pre-wrap font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                    Framtidsfullmakter - så att livet kan rulla på som vanligt
                  </h2>
                  <br />
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Vad är en framtidsfullmakt?
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Fullmakter kan tjäna en mängd olika syften men en som
                      förtjänar lite extra uppmärksamhet och förklaring är
                      framtidsfullmakten.
                      <br />I Sverige spelar framtidsfullmakter en viktig roll
                      vid livsplanering och hantering av personliga
                      angelägenheter. En framtidsfullmakt är ett juridiskt
                      dokument som gör det möjligt för en person att utse en
                      fullmaktshavare att fatta beslut och hantera ekonomiska
                      frågor den dagen man inte kan sköta detta själv. Den här
                      artikeln ger dig en översikt över framtidsfullmakter,
                      inklusive deras syfte, huvuddrag, krav för giltighet,
                      fördelar, vad man bör och inte bör göra samt eventuella
                      rättsliga följder.
                    </p>
                    <br />
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Syftet med en framtidsfullmakt
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base font-semibold">
                      Det primära syftet med en framtidsfullmakt är att se till
                      att dina angelägenheter hanteras den dag du inte kan fatta
                      beslut och agera på egen hand, till exempel på grund av
                      hög ålder, sjukdom eller funktionsnedsättning. Genom att
                      utse en fullmaktshavare i förväg kan du känna dig trygg
                      med att dina ekonomiska angelägenheter, personliga
                      önskemål och vårdbeslut hanteras av någon som du litar på.
                    </p>
                    <br />
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Viktiga beståndsdelar i en framtidsfullmakt
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base font-semibold">
                      Framtidsfullmakter kan skrivas på olika sätt men här är
                      några av de beståndsdelar som bör och brukar finnas i en
                      framtidsfullmakt:
                    </p>
                    <br />
                    <ul className="marker:leading-1 list-disc pl-6 marker:text-[30px]">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Utse en fullmaktstagare.
                        </h4>{" "}
                        Den person som upprättar framtidsfullmakten (
                        <b>Fullmaktsgivaren</b>) utser en eller flera betrodda
                        personer (<b>Fullmaktstagare</b>) att agera å sina
                        vägnar från den dag behovet uppstår.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Giltig över tid.
                        </h4>{" "}
                        En Framtidsfullmakt är utformad för att vara varaktig,
                        det vill säga gälla under en längre tidsperiod. Detta
                        innebär att den förblir giltig även om fullmaktsgivaren
                        blir omyndigförklarad. Detta säkerställer en sömlös
                        övergång av beslutanderätten till fullmaktshavaren när
                        så behövs.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Fullmaktens omfattning.
                        </h4>{" "}
                        Fullmaktsgivaren kan specificera omfattningen av den
                        behörighet som ges till fullmaktshavaren. Det kan
                        inkludera hantering av bankkonton, betalning av
                        räkningar, försäljning eller förvaltning av egendom,
                        investeringsbeslut och hantering av skatteärenden.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Hälso- och sjukvård.
                        </h4>{" "}
                        En Framtidsfullmakt kan även innehålla bestämmelser om
                        hälso- och sjukvårdsbeslut. Det kan innebära att
                        fullmaktshavaren kan fatta vissa medicinska beslut för
                        fullmaktsgivarens räkning om denne inte själv kan göra
                        det.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Möjlighet att återkalla fullmakten.
                        </h4>{" "}
                        Fullmaktsgivaren har rätt att återkalla eller ändra
                        framtidsfullmakten när som helst, så länge denne har
                        rättslig kapacitet att göra det.
                      </li>
                    </ul>
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Krav för giltighet
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      För att en Framtidsfullmakt ska vara giltig måste vissa
                      krav vara uppfyllda:
                    </p>
                    <br />
                    <ul className="marker:leading-1 list-disc pl-6 marker:text-[30px]">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Rättslig handlingsförmåga.
                        </h4>{" "}
                        Fullmaktsgivaren måste ha rättskapacitet att skapa en
                        framtidsfullmakt. Detta innebär att du måste vara vid
                        dina sinnens fulla bruk och förstå innebörden och
                        konsekvenserna av att utse din ställföreträdare.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Skriftlig form.
                        </h4>{" "}
                        En framtidsfullmakt måste vara skriftlig för att vara
                        juridiskt giltig. Det ska tydligt framgå att det är en
                        framtidsfullmakt samt innehålla namn och
                        kontaktuppgifter till fullmaktsgivaren och
                        fullmaktstagaren.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Vittne och intyg.
                        </h4>{" "}
                        Framtidsfullmakten ska bevittnas av två personer som
                        inte är förmånstagare, intressenter, familjemedlemmar,
                        partners eller på annat sätt närstående.
                      </li>
                    </ul>
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Fördelar med en framtidsfullmakt
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Att skriva en framtidsfullmakt har många fördelar, både
                      för dig och dina närmaste:
                    </p>
                    <br />
                    <ul className="marker:leading-1 list-disc pl-6 marker:text-[30px]">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Livsplanering.
                        </h4>{" "}
                        Genom att utse en fullmaktshavare kan du säkerställa att
                        dina angelägenheter sköts i enlighet med dina specifika
                        önskemål och behov.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Undvik förmyndarskap.
                        </h4>{" "}
                        Med en giltig framtidsfullmakt på plats finns inget
                        behov av förmyndarskap den dag du inte längre kan ta
                        hand om dina affärer och åtaganden. Detta kan spara tid,
                        kostnader och potentiella konflikter.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Kontinuitet och effektivitet.
                        </h4>{" "}
                        En framtidsfullmakt möjliggör en smidig övergång av
                        beslutanderätten. Det säkerställer att ekonomiska
                        frågor, fastighetsförvaltning och beslut om hälso- och
                        sjukvård kan hanteras snabbt och utan avbrott.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Lugn och ro - både nu och i framtiden.
                        </h4>{" "}
                        Vetskapen om att dina angelägenheter kommer att tas om
                        hand av en betrodd person istället för av en okänd god
                        man, skapar inte bara bättre förutsättningar för
                        framtiden. Det skapar även lugn och ro här och nu, både
                        för dig och dina närmaste.
                      </li>
                    </ul>
                  </div>
                </div>
                {/*<TipsArticle />*/}
                <div className="last-articles relative mb-20 scroll-mt-20 pt-10 before:absolute before:top-0 before:left-[-50rem] before:h-full before:w-[500%] before:bg-neutral-yellow before:content-['']">
                  <h4 className="relative mb-6 font-intermedium text-xs uppercase tracking-[0.2em] text-[#736F62]">
                    Har du även läst:
                  </h4>
                  <ArticlesSlider
                    articles={articlesFirstLevel.slice(0, 2)}
                    init={false}
                    quantity={2}
                  />
                </div>
                <div
                  data-section
                  id="section_1_3"
                  data-title="Viktigt att tänka på när du skriver en framtidsfullmakt"
                  className="text-block scroll-my-40 lg:scroll-my-20"
                >
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Viktigt att tänka på när du skriver en framtidsfullmakt
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      När du skapar en framtidsfullmakt, tänk på följande för
                      att få till en så tydlig och korrekt framtidsfullmakt som
                      möjligt:
                    </p>
                    <br />
                    <ul className="marker:leading-1 list-disc pl-6 marker:text-[30px]">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Välj någon du litar på.
                        </h4>{" "}
                        Välj noggrant den person som ska vara din
                        fullmaktstagare. Det är viktigt att välja någon som är
                        kompetent, pålitlig och handlingskraftig nog att agera i
                        enlighet med dina intressen och instruktioner.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Var tydlig och precis.
                        </h4>{" "}
                        Var tydlig och specifik i fullmakten om vilka
                        befogenheter fullmaktstagaren har, och under vilka
                        omständigheter hen kan agera. Detta minskar risken för
                        tolkningsmöjligheter och missförstånd.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Undertecknande och vittnen.
                        </h4>{" "}
                        En vanlig fullmakt behöver bara undertecknas av
                        fullmaktsgivaren medan en <b>framtidsfullmakt</b> måste
                        undertecknas av fullmaktsgivaren i närvaro av två
                        oberoende vittnen som också undertecknar fullmakten.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Glöm inte att uppdatera fullmakten.
                        </h4>{" "}
                        Om din livssituation eller dina behov förändras är det
                        viktigt att uppdatera fullmakten så att den fortsätter
                        att återspegla dina intentioner.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Vänta inte med din framtidsfullmakt
                        </h4>{" "}
                        Även om det kan vara svårt att komma till skott - att
                        skjuta på att skriva din framtidsfullmakt kan leda till
                        oförutsedda problem. Det är vår rekommendation att skapa
                        en framtidsfullmakt som en del av din övergripande
                        livsplanering medan du fortfarande är i god form.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Vänta inte med din framtidsfullmakt
                        </h4>{" "}
                        Även om det kan vara svårt att komma till skott - att
                        skjuta på att skriva din framtidsfullmakt kan leda till
                        oförutsedda problem. Det är vår rekommendation att skapa
                        en framtidsfullmakt som en del av din övergripande
                        livsplanering medan du fortfarande är i god form.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Ta hjälp av en jurist.
                        </h4>{" "}
                        Även om det är möjligt att skapa en framtidsfullmakt
                        utan professionell hjälp, rekommenderar vi att söka
                        vägledning från en jurist som är specialiserad på
                        livsplanering. Vi på Fenix Family kan ge råd, se till
                        att de rättsliga kraven uppfylls och skräddarsy din
                        framtidsfullmakt efter din specifika livssituation.
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  src={article[0].hero.href}
                  alt="blog image"
                  className="mb-10"
                  loading="lazy"
                />
                <div
                  data-section
                  id="section_1_4"
                  data-title="Juridiska konsekvenser och skyldigheter"
                  className="text-block scroll-mt-40 lg:scroll-my-20"
                >
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Juridiska konsekvenser och skyldigheter
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Att skapa en framtidsfullmakt har juridiska konsekvenser
                      och skyldigheter som både du som fullmaktsgivare och
                      fullmaktstagaren bör vara medvetna om:
                    </p>
                    <br />
                    <ul className="marker:leading-1 list-disc pl-6 marker:text-[30px]">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Ombudets skyldigheter och ansvar.
                        </h4>{" "}
                        VFullmaktstagaren har en rättslig skyldighet att agera i
                        fullmaktsgivarens bästa intresse och inom ramen för den
                        behörighet som ges i framtidsfullmakten. Hen måste
                        undvika intressekonflikter och utöva sina befogenheter
                        på ett ansvarsfullt sätt.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Finansiell rapportering.
                        </h4>{" "}
                        I vissa fall kan fullmaktshavaren vara skyldig att lämna
                        ekonomiska rapporter och redogöra för sina åtgärder som
                        en del av sin plikt gentemot fullmaktsgivaren.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Återkallelse och uppsägning.
                        </h4>{" "}
                        Fullmaktsgivaren har rätt att återkalla eller modifiera
                        framtidsfullmakten så länge denne har rättslig
                        handlingsförmåga att göra detta. Återkallelse bör ske
                        skriftligen och meddelas alla relevanta parter,
                        inklusive finansiella institutioner och vårdgivare.
                      </li>
                    </ul>
                    {/*<h4 className="mb-0 font-intermedium text-[18px] ">*/}
                    {/*  Återkallelse och uppsägning.*/}
                    {/*</h4>*/}
                    {/*<p className="whitespace-pre-wrap font-interlight text-base font-semibold">*/}
                    {/*  Fullmaktsgivaren har rätt att återkalla eller modifiera framtidsfullmakten så länge denne har rättslig handlingsförmåga att göra detta. Återkallelse bör ske skriftligen och meddelas alla relevanta parter, inklusive finansiella institutioner och vårdgivare.*/}
                    {/*</p>*/}
                  </div>
                </div>
                {/*<QuoteArticle />*/}
                <div className="text-block pb-10">
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Sammanfattning av framtidsfullmakter
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Framtidsfullmakter är ett värdefullt verktyg för att
                      planera inför den dag du själv inte kan sköta dina affärer
                      och åtaganden. Genom att skapa en juridiskt giltig och
                      omfattande Framtidsfullmakt kan du utse en betrodd
                      ställföreträdare att fatta beslut å dina vägnar. Det är
                      viktigt att förstå vilka krav som ställs för att en
                      framtidsfullmakt ska vara giltig, vad man bör och inte bör
                      tänka på när man upprättar en framtidsfullmakt samt vilka
                      rättsliga konsekvenser detta medför. Att söka vägledning
                      från oss på Fenix Family eller annan kompetent jurist, kan
                      säkerställa att lagen följs och att dina behov och
                      önskemål följs ordentligt. Observera att denna artikel ger
                      allmän vägledning om framtidsfullmakter. Vi rekommenderar
                      att prata med en kvalificerad jurist för råd baserat på
                      din specifika livssituation.
                      <br />
                      Hoppas att den här artikeln gav dig den kunskap du letade
                      efter! Har du frågor är det bara att kontakta oss.
                    </p>
                  </div>
                  <br />
                  {renderLoginButton(
                    `bg-deep-blue text-white hover:border-[#fff] hover:bg-[#0C2632]/10`,
                    `#fff`
                  )}
                  <br />
                  <br />
                  {/*<Link className="inline-flex items-center" to={"/"}>*/}
                  {/*  <span className="border-b border-b-transparent font-intermedium text-[14px] font-semibold uppercase not-italic tracking-wider text-deep-blue hover:border-b hover:border-b-deep-blue">*/}
                  {/*    Läs mer i denna sekundära artikeln*/}
                  {/*  </span>{" "}*/}
                  {/*  <span className="pl-2">*/}
                  {/*    {getButtonArrow("#43262D", "20px", "20px")}*/}
                  {/*  </span>*/}
                  {/*</Link>*/}
                </div>
                {/*<TableArticle />*/}
                <div
                  data-section
                  id="steps"
                  data-title="Varför Fenix livsplanering?"
                  className="step-block scroll-my-40 pb-10 lg:scroll-my-20"
                >
                  <div className="inline-flex flex-col items-center justify-start gap-10">
                    <h2 className="text-center font-internormal text-base font-semibold uppercase leading-normal tracking-[4px]">
                      Varför Fenix livsplanering?
                    </h2>
                    <div className="flex flex-col items-start justify-start gap-8 md:flex-row lg:inline-flex lg:gap-20">
                      {steps.map((step, index) => (
                        <div
                          key={index}
                          className="inline-flex flex-col items-center justify-start gap-6"
                        >
                          <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full bg-[#E8E3D2] text-center text-[28px] font-semibold uppercase lg:h-[118px] lg:w-[118px] lg:text-[42px]">
                            {index + 1}
                          </div>
                          <div className="max-w-[194px] text-center text-[13px] font-normal leading-none">
                            {step.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <VideoItem />
                <br />
                <br />
                <br />
                <TrustboxArticle />
                {/*<div className="inline-flex flex-col items-start justify-start border-t border-[#CECABA] p-4">*/}
                {/*  {isDesktop ? (*/}
                {/*    <div className="author-box inline-flex items-start justify-start gap-3 self-stretch">*/}
                {/*      <img*/}
                {/*        src={article[0].authorImage.href}*/}
                {/*        alt={article[0].name}*/}
                {/*        className="h-[102px] w-[102px] rounded-full"*/}
                {/*        loading="lazy"*/}
                {/*      />*/}
                {/*      <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-3">*/}
                {/*        <div className="flex flex-col items-start justify-start gap-1">*/}
                {/*          <div className="text-lg font-semibold leading-normal text-deep-blue">*/}
                {/*            {article[0].name}*/}
                {/*          </div>*/}
                {/*          <div className="text-[13px] font-normal italic leading-none text-[#736F62]">*/}
                {/*            {article[0].title}*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*        <div className="self-stretch text-[13px] font-normal italic leading-none text-[#736F62]">*/}
                {/*          {article[0].text}*/}
                {/*        </div>*/}
                {/*        <div className="inline-flex items-center justify-start gap-3 self-stretch">*/}
                {/*          <div className="relative">*/}
                {/*            <a*/}
                {/*              href="mailto:te@te.com"*/}
                {/*              className="cursor-pointer hover:underline"*/}
                {/*            >*/}
                {/*              {getEmail()}*/}
                {/*            </a>*/}
                {/*          </div>*/}
                {/*          <div className="relative h-6 w-6">{getLink()}</div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  ) : (*/}
                {/*    <div className="author-box inline-flex flex-col items-start justify-start">*/}
                {/*      <div className="flex flex-col items-start justify-start gap-3 self-stretch">*/}
                {/*        <div className="inline-flex items-center justify-start gap-3 self-stretch">*/}
                {/*          <img*/}
                {/*            src={article[0].authorImage.href}*/}
                {/*            alt={article[0].name}*/}
                {/*            className="h-[102px] w-[102px] rounded-full"*/}
                {/*            loading="lazy"*/}
                {/*          />*/}
                {/*          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">*/}
                {/*            <div className="text-lg font-semibold leading-normal text-deep-blue">*/}
                {/*              {article[0].name}*/}
                {/*            </div>*/}
                {/*            <div className="text-[13px] font-normal italic leading-none text-[#736F62]">*/}
                {/*              {article[0].title}*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*        <div className="self-stretch text-[13px] font-normal italic leading-none text-[#736F62]">*/}
                {/*          {article[0].text}*/}
                {/*        </div>*/}
                {/*        <div className="inline-flex items-center justify-start gap-3 self-stretch">*/}
                {/*          <div className="relative">*/}
                {/*            <a*/}
                {/*              href="mailto:te@te.com"*/}
                {/*              className="cursor-pointer hover:underline"*/}
                {/*            >*/}
                {/*              {getEmail()}*/}
                {/*            </a>*/}
                {/*          </div>*/}
                {/*          <div className="relative h-6 w-6">{getLink()}</div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  )}*/}
                {/*</div>*/}
              </div>
            </article>
          </div>
        </div>
        <div
          data-section
          id="faq"
          data-title="FAQ"
          className="relative w-full border-t border-[#CECABA] text-deep-blue lg:border-t-0"
        >
          <div className="mx-auto px-5 pt-28 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:border-t lg:border-[#CECABA] lg:pt-24 lg:pb-32 xl:max-w-[1360px]">
            <div className="mx-auto max-w-[980px]">
              <h2 className="text-center font-interblack text-[28px] italic leading-[2.7rem] text-deep-blue xl:text-[48px] xl:leading-[4.5rem]">
                FAQ
              </h2>
            </div>
            <div className="mx-auto mt-3 max-w-[985px] pt-5 pb-24">
              <Accordion items={faqBlock} />
            </div>
          </div>
        </div>
        <div className="relative w-full bg-[#F3F0E6] text-deep-blue">
          <div className="mx-auto px-5 pt-12 pb-12 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-9 xl:max-w-[1360px]">
            <h2 className="mb-6 pt-8 font-intermedium text-base uppercase tracking-[.25em] lg:mb-3">
              Populära artiklar
            </h2>
            <ArticlesSlider
              articles={articlesFirstLevel.slice(0, 4)}
              init={false}
              quantity={4}
            />
          </div>
        </div>
      </div>
      <Footer />
    </ServiceSectionProvider>
  );
}
