import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Sticky from "react-stickynode";

import { articles, faqBlock, steps } from "../assets/data/datalist";
import Accordion from "../components/Accordion";
import QuoteArticle from "../components/articles/QuoteArticle";
import TableArticle from "../components/articles/TableArticle";
import TipsArticle from "../components/articles/TipsArticle";
import TrustboxArticle from "../components/articles/TrustboxArticle";
import Footer from "../components/Footer";
import HeadMenu from "../components/HeadMenu";
import ArticlesSlider from "../components/slider/ArticlesSlider";
import VideoItem from "../components/VideoItem";
import { ServiceSectionProvider } from "../context/ServiceSectionContext";
import { useMediaQuery } from "../services/hooks/useMediaQuery";
import {
  getBankIdIcon,
  getButtonArrow,
  getEmail,
  getLink,
  getViewsIcon,
} from "../services/icons";
import { useScrollPosition } from "../services/utils";

type ArticleType = {
  id: number;
  date: string;
  views: string;
  hero: URL;
  thumb: URL;
  authorImage?: URL;
  name: string;
  title: string;
  link: string;
  categoryFirstLevel: string;
  categoryFirstLevelLink: string;
  text: string;
};

export const article: ArticleType[] = [
  {
    id: 1,
    date: "18 augusti 2023",
    views: "978",
    hero: new URL(
      "../assets/images/article/hero_2_1600x960.jpg",
      import.meta.url
    ),
    thumb: new URL(
      "../assets/images/article/thumb/hero_2_310x220.jpg",
      import.meta.url
    ),
    authorImage: new URL("../assets/images/advisor.png", import.meta.url),
    name: "Henning Wijkmark",
    title: "Arvsrätt",
    link: "arv",
    categoryFirstLevel: "Arv",
    categoryFirstLevelLink: "arv",
    text: 'Den svenska arvsrätten styrs av Ärvdabalken. Det är den lag som styr fördelningen av tillgångar efter en persons bortgång. Att förstå lagarna och logiken i den är grundläggande för att du ska kunna var säker på att din egendom fördelas som du vill efter din bortgång. Den här artikeln syftar till att ge en översikt över svensk arvsrätt, bland annat dess historiska bakgrund, införandet av Ärvdabalken, vem som ärver, vad som händer om du inte är gift med din partner, hur barn ärver, arvsklasser, skillnader från andra större västländer, skatter, förskott på arv, vad man ska tänka på vid handläggning av ärenden som påverkas av arvsrätten, en förklaring av termerna "laglott" och "arvslott" och lite till. Allt får inte plats i en artikel (det finns en anledning till att lagboken är tjock) så om du behöver ytterligare vägledning är du varmt välkommen att kontakta oss på Fenix Family.',
  },
];

export default function Post2({
  title,
  link,
}: {
  title?: string;
  link?: string;
}) {
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }
  const { id } = useParams<{ id: string }>();
  const [isHover, setIsHover] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(true);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [activeSection, setActiveSection] = useState(null);
  const [activeTitle, setActiveTitle] = useState("Inledning");
  const observer = useRef(null);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    document.title = title!;
    // create new instance and pass a callback function
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find(
          (entry) => entry.isIntersecting
        )?.target;
        // Update state with the visible section ID
        if (visibleSection) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setActiveSection(visibleSection.id);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setActiveTitle(visibleSection.getAttribute("data-title"));
        }
      },
      {
        threshold: isDesktop ? 0.25 : 0,
      }
    );

    // Get custom attribute data-section from all sections
    const sections = document.querySelectorAll("[data-section]");

    sections.forEach((section) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      observer.current.observe(section);
    });
    // Cleanup function to remove observer
    return () => {
      sections.forEach((section) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        observer.current.unobserve(section);
      });
    };
  }, []);

  function handleClick() {
    setOpenSidebar(!openSidebar);
  }

  const post = articles.filter((oneItem) => oneItem.link == id);

  const renderLoginButton = (buttonClass: string, iconColor: string) => {
    return (
      <Link
        to="/login"
        className={
          buttonClass +
          ` inline-flex items-center gap-2 rounded border-[1px] border-deep-blue py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] lg:gap-3 lg:self-end`
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon("#fff", "20", "100%")
          : getBankIdIcon(iconColor, "20", "100%")}
        Logga in
      </Link>
    );
  };

  const navigationList = () => {
    return (
      <ul className="relative inline-flex list-none flex-col items-start justify-start gap-6 pl-6 pr-5 before:absolute before:top-0 before:left-[5px] before:h-full before:w-[1px] before:bg-[#CECABA] before:content-['']">
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest  before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "inledning"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
            to="#inledning"
          >
            Inledning
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "section_1_1"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#section_1_1"
          >
            Syftet med Ärvdabalken
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "section_1_2"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#section_1_2"
          >
            Vad händer om du inte är gift med din partner?
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "section_1_3"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#section_1_3"
          >
            Laglott och Arvslott - vad är det?
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "section_1_4"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#section_1_4"
          >
            Vanliga frågor om arvsrätt
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "steps"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#steps"
          >
            Varför Fenix livsplanering?
          </HashLink>
        </li>
        <li
          className={`relative self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest before:absolute before:top-0 before:-left-6 before:z-20 before:h-[12px] before:w-[12px] before:rounded-full before:content-[''] after:absolute after:-left-[30px] after:-top-[6px] after:z-10 after:h-[25px] after:w-[25px] after:rounded-full after:bg-[#E8E3D2] after:content-[''] ${
            activeSection === "faq"
              ? " text-[#0C2632] before:bg-[#0C2632]"
              : " text-[#736F62] before:bg-[#CECABA]"
          }`}
        >
          <HashLink
            onClick={handleClick}
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            to="#faq"
          >
            FAQ
          </HashLink>
        </li>
      </ul>
    );
  };

  const articlesFirstLevel = articles.filter((object) => {
    return (
      object.categoryFirstLevel !== null &&
      object.categorySecondLevel === null &&
      object.link !== article[0].link
    );
  });

  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-white",
            "fixed z-1000 w-full transition-colors"
          )}
        >
          <HeadMenu
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={scrollPosition > 84 ? "#0C2632" : "#0C2632"}
          />
        </div>
        <div className="hero-article relative w-full overflow-hidden text-deep-blue">
          <img
            src={article[0].hero.href}
            alt={article[0].title}
            className="absolute left-0 top-0 z-10 h-full w-full object-cover"
            loading="lazy"
          />
          <div className="absolute left-0 top-0 z-20 h-full w-full bg-[#0C2632]/10" />
          <div className="z-1 relative z-30 mx-auto px-5 pb-6 pt-24 sm:max-w-[640px] md:max-w-[768px] lg:h-[735px] lg:max-w-[1024px] lg:pb-16 lg:pt-24 xl:max-w-[1360px]">
            <div className="hero-article flex h-full max-w-[750px] flex-col justify-between">
              <ul className="float-left inline space-x-2 text-[11px] capitalize text-white lg:flex">
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/"}>hem</Link>
                </li>
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/artiklar/"}>artiklar</Link>
                </li>
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/artiklar/arv/"}>Arv</Link>
                </li>
                <li className="pointer-events-none inline">
                  <Link to={`/artiklar/arv/${article[0].link}`}>Arvsrätt</Link>
                </li>
              </ul>
              <div className="article-heading mt-28 lg:mt-0">
                <p className="text-xs font-medium uppercase tracking-[.25em] text-white">
                  {article[0].date}
                </p>
                <h1 className="mb-2 font-interblack text-[36px] italic leading-[2.7rem] text-white lg:mt-20 lg:text-[64px] xl:mt-5 xl:leading-[4.4rem]">
                  {article[0].title}
                </h1>
                <div className="flex text-[13px] text-white">
                  av {article[0].name}
                  <p className="flex items-center pl-5 text-white">
                    {getViewsIcon("#E8E3D2")}
                    <span className="pl-2">{article[0].views}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="article relative w-full scroll-mt-40 overflow-hidden">
          <div className="relative mx-auto flex flex-col px-5 pt-0 pb-16 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:flex-row lg:pt-20 xl:max-w-[1360px]">
            <aside className="lg:rounded-b-0 absolute left-0 top-0 flex w-full lg:relative lg:max-w-[325px]">
              {isDesktop ? (
                <Sticky
                  top="#header"
                  bottomBoundary="#content"
                  activeClass="active w-full"
                  innerZ={999}
                >
                  <div className="sidebar self-start overflow-hidden rounded-[8px] bg-[#E8E3D2]">
                    <div className="inline-flex w-full items-start justify-start gap-3 bg-[#E8E3D2] px-6 py-10 lg:max-w-[324px]">
                      {navigationList()}
                    </div>
                  </div>
                </Sticky>
              ) : (
                <Sticky top="#header" activeClass="active w-full" innerZ={999}>
                  <div
                    className={
                      !openSidebar
                        ? "sidebar self-start rounded-b-[8px] bg-[#E8E3D2]"
                        : "sidebar h-[76px] self-start overflow-hidden rounded-b-[8px] bg-[#E8E3D2]"
                    }
                  >
                    <button
                      onClick={handleClick}
                      className={`relative h-[76px] w-full pl-5 pr-7 text-left font-internormal text-xs font-medium uppercase tracking-[0.2em] after:absolute after:right-5 after:top-7 after:h-[10px] after:w-[10px]  after:transform after:border-b after:border-r after:border-black/70 lg:hidden ${
                        openSidebar ? " after:rotate-45" : "after:rotate-225"
                      }`}
                    >
                      {activeTitle}
                    </button>
                    <div className="inline-flex w-full items-start justify-start gap-3 bg-[#E8E3D2] px-6 pt-3 pb-10">
                      {navigationList()}
                    </div>
                  </div>
                </Sticky>
              )}
            </aside>
            <article
              id="content"
              className="article-content w-full pt-28 lg:pt-0"
            >
              <div className="max-w-[760px] text-deep-blue lg:ml-24">
                <div className="pb-10 lg:pb-0"></div>
                <div
                  id="inledning"
                  data-title="Inledning"
                  data-section
                  className="text-block scroll-my-40 pb-10"
                >
                  <h2 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                    Inledning
                  </h2>
                  <p className="whitespace-pre-wrap font-interlight text-base">
                    Den svenska arvsrätten styrs av Ärvdabalken. Det är den lag
                    som styr fördelningen av tillgångar efter en persons
                    bortgång. Att förstå lagarna och logiken i den är
                    grundläggande för att du ska kunna var säker på att din
                    egendom fördelas som du vill efter din bortgång. Den här
                    artikeln syftar till att ge en översikt över svensk
                    arvsrätt, bland annat dess historiska bakgrund, införandet
                    av Ärvdabalken, vem som ärver, vad som händer om du inte är
                    gift med din partner, hur barn ärver, arvsklasser, skatter,
                    förskott på arv, vad man ska tänka på vid handläggning av
                    ärenden som påverkas av arvsrätten, en förklaring av
                    termerna "laglott" och "arvslott" och lite till. Allt får
                    inte plats i en artikel (det finns en anledning till att
                    lagboken är tjock) så om du behöver ytterligare vägledning
                    är du varmt välkommen att kontakta oss på Fenix Family.
                  </p>
                  <br />
                </div>
                <div className="text-block pb-10">
                  <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                    Historisk bakgrund till den svenska arvsrätten
                  </h3>
                  <p className="whitespace-pre-wrap font-interlight text-base">
                    Den svenska arvsrättens ursprung kan spåras tillbaka till
                    forntiden då sedvänjor och traditioner reglerade
                    fördelningen av egendom efter dödsfall. Under medeltiden
                    genomgick det svenska samhället förändringar och begreppet
                    arv började ta form. Det var dock inte förrän på 1600-talet
                    som Sverige införde en mer formell lagstiftning kring
                    arvsfrågor.
                  </p>
                  <br />
                  <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                    Introduktion av Ärvdabalken
                  </h3>
                  <p className="whitespace-pre-wrap font-interlight text-base">
                    Ärvdabalken är den primära lagstiftningen för arvsfrågor i
                    Sverige. Den antogs 1734 och har sedan dess genomgått flera
                    revideringar för att anpassas till samhällsförändringar och
                    rättslig utveckling. Ärvdabalken utgör den rättsliga ramen
                    för fördelningen av tillgångar, rättigheter och skyldigheter
                    i samband med arv.
                  </p>
                  <br />
                </div>
                <div
                  data-section
                  data-title="Syftet med Ärvdabalken"
                  id="section_1_1"
                  className="headline-block scroll-my-40 pb-10 lg:scroll-my-20"
                >
                  <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                    Syftet med Ärvdabalken
                  </h3>
                  <p className="whitespace-pre-wrap font-interlight text-base">
                    Ärvdabalken tjänar flera syften inom det svenska
                    rättssystemet. Dess huvudsakliga mål är följande:
                  </p>
                  <br />
                  <ul className="marker:leading-1 list-disc pl-6 marker:text-[30px]">
                    <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                      <h4 className="mb-0 inline font-intermedium text-[18px] ">
                        Säkerställa rättvis fördelning.
                      </h4>{" "}
                      Ärvdabalken syftar till att säkerställa en rättvis
                      fördelning av tillgångar mellan arvingar samt skydda
                      rättigheterna för nära anhöriga, t ex barn och makar.
                    </li>
                    <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                      <h4 className="mb-0 inline font-intermedium text-[18px] ">
                        Balansera testamentarisk frihet och tvångsarv.
                      </h4>{" "}
                      Ärvdabalken skapar en balans mellan individens frihet att
                      bestämma över sina tillgångar genom ett testamente
                      (testamentarisk frihet) och principen om tvångsarv, som
                      garanterar vissa anhöriga en skyddad andel av arvet.
                    </li>
                    <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                      <h4 className="mb-0 inline font-intermedium text-[18px] ">
                        Skapa rättslig säkerhet.
                      </h4>{" "}
                      Lagstiftningen ger tydliga riktlinjer och regler för
                      arvsfrågor, vilket minskar risken för tvister och
                      konflikter mellan arvingar.
                    </li>
                    <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                      <h4 className="mb-0 inline font-intermedium text-[18px] ">
                        Följer samhällets utveckling.
                      </h4>{" "}
                      Under årens lopp har Ärvdabalken reviderats för att
                      återspegla förändringar i samhället, t ex att erkänna
                      rättigheterna för barn födda utanför äktenskapet och ta
                      itu med moderna familjestrukturer.
                    </li>
                  </ul>
                  {/*<h4 className="mb-0 font-intermedium text-[18px] ">*/}
                  {/*  Bankärenden.*/}
                  {/*</h4>*/}
                  {/*<p className="whitespace-pre-wrap font-interlight text-base font-semibold">*/}
                  {/*  Om du har svårt att hantera dina bankaffärer kan en fullmakt ge en person befogenhet att agera som ditt ombud. Det kan inkludera att sätta in eller ta ut pengar, betala räkningar eller hantera investeringar.*/}
                  {/*</p>*/}
                </div>
                <div className="headline-block pb-10">
                  <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                    Vem ärver enligt svensk arvsrätt?
                  </h3>
                  <p className="whitespace-pre-wrap font-interlight text-base">
                    I svensk arvsrätt är det reglerna om intestatarv som styr,
                    det vill säga hur tillgångar fördelas när en person går bort
                    utan att efterlämna ett giltigt testamente. Här är de
                    viktigaste punkterna när det gäller arv:
                  </p>
                  <br />
                  <ul className="marker:leading-1 list-disc pl-6 marker:text-[30px]">
                    <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                      <h4 className="mb-0 inline font-intermedium text-[18px] ">
                        Efterlevande maka eller make.
                      </h4>{" "}
                      Om den avlidne var gift har, som huvudregel, den
                      efterlevande makan eller maken rätt till hela
                      kvarlåtenskapen. Om den avlidne hade barn från tidigare
                      relation - särkullbarn - har särkullbarnen eller deras
                      avkommor omedelbart rätt till sitt arv.
                    </li>
                    <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                      <h4 className="mb-0 inline font-intermedium text-[18px] ">
                        Barn.
                      </h4>{" "}
                      Barn har rätt att ärva sina föräldrar. Om det inte finns
                      något testamente har alla barn rätt till en lika stor
                      andel av kvarlåtenskapen. Adopterade barn har samma
                      rättigheter som biologiska barn.
                    </li>
                    <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                      <h4 className="mb-0 inline font-intermedium text-[18px] ">
                        Andra släktingar.
                      </h4>{" "}
                      Om den avlidne inte har någon efterlevande maka, make
                      eller barn kan arvet gå till andra släktingar, t.ex.
                      föräldrar, syskon eller mer avlägsna släktingar, beroende
                      på de specifika omständigheterna.
                    </li>
                  </ul>
                </div>
                <div
                  data-section
                  data-title="Vad händer om du inte är gift med din partner?"
                  id="section_1_2"
                  className="text-block scroll-my-40 pb-10 lg:scroll-my-20"
                >
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Vad händer om du inte är gift med din partner?
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      I Sverige har äktenskapets rättsliga status stor betydelse
                      för arvet. Om du inte är gift med din partner är det
                      viktigt att ha koll på följande:
                    </p>
                    <br />
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Avsaknad av automatiskt arv.
                        </h4>{" "}
                        Till skillnad från gifta makar har ogifta partners ingen
                        automatisk rätt att ärva varandra enligt svensk
                        arvsrätt. Detta innebär att en ogift partner utan ett
                        giltigt testamente inte får någon del av den avlidne
                        partnerns kvarlåtenskap.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Testamentarisk frihet.
                        </h4>{" "}
                        För att vara säker på att din ogifta partner får del av
                        arvet är det viktigt att upprätta ett giltigt
                        testamente. Genom att ange din partner som förmånstagare
                        kan du åsidosätta standardreglerna för intestatarv och
                        tillgodose dem i din livsplanering.
                      </li>
                    </ul>
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Hur ärver barn enligt svensk arvsrätt?
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Barn spelar en viktig roll i svensk arvsrätt. Här är några
                      viktiga punkter när det gäller barns arv:
                    </p>
                    <br />
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Lika arv.
                        </h4>{" "}
                        I avsaknad av testamente har alla barn rätt till en lika
                        stor andel av kvarlåtenskapen. Detta gäller oavsett
                        antalet barn eller deras biologiska eller adopterade
                        status.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Särkullbarn.
                        </h4>{" "}
                        Har den avlidne barn från tidigare relationer har dessa
                        eller deras avkommor omedelbart rätt till sina
                        respektive arvslotter.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Förskott på arv.
                        </h4>{" "}
                        Vissa vill ge sina bröstarvingar arv under sin livstid.
                        På så sätt kan bröstarvingar få sitt arv tidigare, medan
                        man fortfarande är i livet. Förskottet på arvet kan dras
                        av från bröstarvingarnas andelar när man avlider.
                      </li>
                    </ul>
                  </div>
                  <div className="headline-block">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Arvsklasser och juridiska rättigheter
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Den svenska arvsrätten specificerar olika klasser av
                      arvingar och tilldelar varje klass egna juridiska
                      rättigheter. Dessa klasser avgör arvsordningen. Här är de
                      primära klasserna:
                    </p>
                    <br />
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Den första arvsklassen.
                        </h4>{" "}
                        Den första arvsklassen omfattar den avlidna personens
                        barn. Om några barn har avlidit kan deras barn (den
                        avlidna personens barnbarn) ärva i deras ställe. Är inte
                        dessa i livet ärver barnbarns barn osv.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Den andra arvsklassen.
                        </h4>{" "}
                        Om det inte finns några efterlevande medlemmar i den
                        första arvsklassen träder den andra arvsklassen in.
                        Denna klass omfattar den avlidna personens föräldrar och
                        deras avkomlingar om föräldrarna har gått bort.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Den tredje arvsklassen.
                        </h4>{" "}
                        Om det inte finns några överlevande medlemmar i den
                        första eller andra arvsklassen, ärver den tredje
                        arvsklassen. Denna klass omfattar far- och morföräldrar
                        och om de är bortgångna träder i sista hand deras barn
                        in. Kusiner har ingen laglig rätt att ärva varandra.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text-block pb-10">
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Hur skiljer sig svensk arvsrätt från andra länders?
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Den svenska arvsrätten skiljer sig från arvsrätten i andra
                      större västländer på flera sätt. Här är några viktiga
                      skillnader:
                    </p>
                    <br />
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Tvångsarv.
                        </h4>{" "}
                        Den svenska arvsrätten innehåller principen om
                        tvångsarv, vilket innebär att Arvsklass 1 -
                        bröstarvingar - inte kan göras helt arvlösa. De har rätt
                        till en skyddad andel av kvarlåtenskapen, så kallad
                        "laglott". Laglotten säkerställer att dessa släktingar
                        har rätt till en del av kvarlåtenskapen, oavsett
                        innehållet i ett testamente.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Testamentarisk frihet.
                        </h4>{" "}
                        Medan tvångsarv gäller för vissa släktingar, tillåter
                        svensk lag också testamentarisk frihet. Detta innebär
                        att personer har rätt att fördela delar av sin
                        kvarlåtenskap genom ett giltigt testamente. Den del som
                        omfattas av tvångsarv (laglott) kan dock inte helt
                        bortses ifrån.
                      </li>
                    </ul>
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Förskott på arv: Saker att tänka på
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Att ge förskott på arv till barn är vanligt i Sverige. Här
                      är några saker som kan vara bra att tänka på:
                    </p>
                    <br />
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Juridiska formaliteter.
                        </h4>{" "}
                        Att ge förskott på arv kräver ingen juridisk
                        dokumentation. Det kan dock vara bra att upprätta ett
                        korrekt gåvobrev, för att undvika framtida konflikter.
                        Däremot motsatsen, dvs om du vill ge en större gåva till
                        en bröstarvinge utan att det ska anses vara förskott på
                        arv, så kräver det en juridisk skrivelse. Ta gärna hjälp
                        av oss på Fenix Family för att allt ska bli korrekt.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Likabehandling.
                        </h4>{" "}
                        Om du har flera bröstarvingar är det viktigt att ha koll
                        på vem som fått förskott på arv och hur mycket. På så
                        vis minskar riskerna för tvister när ditt arv ska
                        fördelas efter du avlidit.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Professionell rådgivning.
                        </h4>{" "}
                        Att söka professionell rådgivning från oss på Fenix
                        Family eller någon annan juridisk expert kan hjälpa dig
                        att navigera i processen kring att ge ett förskott på
                        arv. Vi kan vägleda dig genom de juridiska
                        formaliteterna och se till att dina önskemål blir
                        korrekt dokumenterade. Att göra vår livsplanering på{" "}
                        <a
                          href="https://fenixfamily.se/"
                          title="Fenix Family AB"
                        >
                          fenixfamily.se
                        </a>{" "}
                        brukar lösa de vanligaste knutarna!
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  data-section
                  data-title="Laglott och Arvslott - vad är det?"
                  id="section_1_3"
                  className="text-block scroll-my-40 lg:scroll-my-20"
                >
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Laglott och Arvslott - vad är det?
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Laglott och Arvslott är viktiga begrepp inom svensk
                      arvsrätt. Här följer en förklaring av respektive begrepp:
                    </p>
                    <br />
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Laglott.
                        </h4>{" "}
                        Med laglott avses den skyddade andel av arvet som
                        bröstarvingar har rätt till enligt svensk arvsrätt.
                        T.ex. barn har rätt till laglott, vilket innebär att de
                        inte kan med säkerhet göras helt arvlösa. Laglotten är
                        avsedd att utgöra ett skyddsnät för nära släktingar och
                        säkerställa att de får en del av arvet, även om de inte
                        är förmånstagare som anges i testamentet.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Arvslott.
                        </h4>{" "}
                        Arvslott är den andel som en arvinge har rätt till
                        enligt svensk arvsrätt. Det är den del av arvet som en
                        arvinge skulle få om det inte fanns något testamente
                        eller om testamentet inte fullt ut fördelar
                        tillgångarna.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Bröstarvinge.
                        </h4>{" "}
                        Den avlidnes barn, barnbarn osv i rakt nedstigande led.
                      </li>
                    </ul>
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Skatter och arv
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Arv i Sverige kan vara föremål för vissa skatter. Här
                      följer några viktiga skatteöverväganden:
                    </p>
                    <br />
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Arvsskatt.
                        </h4>{" "}
                        Sverige avskaffade arvsskatten 2005. Detta gör att det i
                        allmänhet inte finns någon arvsskatt på överföring av
                        tillgångar från en avliden person till arvingar.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Gåvoskatt.
                        </h4>{" "}
                        Även gåvoskatten avskaffades 2005.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Reavinstskatt.
                        </h4>{" "}
                        I vissa fall kan arv av tillgångar utlösa reavinstskatt
                        om tillgångarna senare säljs. Det är viktigt att rådgöra
                        med en skatteexpert eller finansiell rådgivare för att
                        förstå de potentiella skattekonsekvenserna av ärvda
                        tillgångar.
                      </li>
                    </ul>
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Bra att tänka på vid frågor som rör arv och arvsrätt
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      När man hanterar frågor som påverkas av svensk arvsrätt
                      finns det flera viktiga överväganden att ha i åtanke. Här
                      är några viktiga punkter:
                    </p>
                    <br />
                    <ul className="list-disc pl-6 marker:text-[30px] marker:leading-none">
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Sök professionell rådgivning.
                        </h4>{" "}
                        Svensk arvsrätt kan vara komplex, och det är viktigt att
                        söka professionell rådgivning från en jurist. De kan ge
                        vägledning som är skräddarsydd för dina specifika
                        omständigheter och se till att du följer lagen och
                        skyddar dina intressen. Vi på Fenix Family hjälper dig
                        gärna med din livsplanering och alla frågor som kan
                        uppstå i samband med arv och dödsfall i familjen. Att
                        göra vår livsplanering på{" "}
                        <a
                          href="https://fenixfamily.se/"
                          title="Fenix Family AB"
                        >
                          fenixfamily.se
                        </a>{" "}
                        brukar räcka ganska långt!
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Se över och uppdatera.
                        </h4>{" "}
                        Se regelbundet över och uppdatera din livsplanering och
                        ditt testamente så att de stämmer med eventuella
                        förändringar i din livssituation. Livshändelser som
                        äktenskap, skilsmässa, födslar eller dödsfall kan göra
                        att du behöver skriva om ditt testamente.
                      </li>
                      <li className="mb-4 whitespace-pre-wrap font-interlight text-base font-semibold italic leading-[22px]">
                        <h4 className="mb-0 inline font-intermedium text-[18px] ">
                          Kommunicera med nära och kära.
                        </h4>{" "}
                        Kommunicera öppet dina avsikter och din livsplanering
                        med dina nära och kära. Att diskutera dina önskemål och
                        förklara resonemanget bakom vissa beslut kan hjälpa till
                        att förhindra missförstånd och potentiella konflikter
                        mellan familjemedlemmar.
                      </li>
                    </ul>
                  </div>
                  <div className="headline-block pb-10">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Sammanfattning
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Att förstå svensk arvsrätt är viktigt för alla som är
                      bosatta i Sverige eller har tillgångar i landet. Genom att
                      bekanta dig med reglerna, arvsklasser, skillnader mot
                      andra länder, skatter, förskott på arv, laglott, arvslott
                      och andra viktiga frågor, kan du enklare navigera vad som
                      gäller vid arv. Vi på Fenix Family hjälper dig gärna att
                      göra en livsplanering så att dina tillgångar fördelas som
                      du vill. För att skapa tydlighet, lugn och ro för dig och
                      dina närmaste, både nu och i framtiden.
                      <br />
                      <br />
                      Observera att denna artikel ger allmän vägledning om
                      svensk arvsrätt och inte är avsedd som juridisk
                      rådgivning. Det är viktigt att rådgöra med en jurist för
                      personlig rådgivning baserad på dina specifika
                      omständigheter och för att säkerställa efterlevnad av
                      tillämpliga lagar och förordningar.
                    </p>
                  </div>
                </div>
                <div className="text-block scroll-my-40 pb-10 lg:scroll-my-20"></div>
                {/*<QuoteArticle />*/}
                <TrustboxArticle />
                <div className="mb-20"></div>
                {/*<TipsArticle />*/}
                <div className="last-articles relative mb-20 pt-10 before:absolute before:top-0 before:left-[-50rem] before:h-full before:w-[500%] before:bg-neutral-yellow before:content-['']">
                  <h4 className="relative mb-6 font-intermedium text-xs uppercase tracking-[0.2em] text-[#736F62]">
                    Har du även läst:
                  </h4>
                  <ArticlesSlider
                    articles={articlesFirstLevel.slice(0, 2)}
                    init={false}
                    quantity={2}
                  />
                </div>
                <div
                  data-section
                  id="section_1_4"
                  data-title="Vanliga frågor om arvsrätt"
                  className="text-block scroll-my-40 pb-10 lg:scroll-my-20"
                >
                  <h2 className="mb-3 whitespace-pre-wrap font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                    Vanliga frågor om arvsrätt
                  </h2>
                  <br />
                  <div className="headline-block">
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Vad menar man med arvsklasser?
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base">
                      Den legala arvsordningen består av tre arvsklasser som
                      beskriver i vilken ordning arvet fördelas. Det innebär att
                      när en person avlidit ska arvet fördelas enligt den legala
                      arvsordningen i ärvdabalken. Kvarlåtenskapen i ett dödsbo
                      fördelas enligt arvsordningen om det inte finns ett
                      testamente.
                      <br />
                      <br />
                      Den legala arvsordningen består av tre arvsklasser som
                      beskriver i vilken ordning arvet fördelas. Det innebär att
                      när en person avlidit ska arvet fördelas enligt den legala
                      arvsordningen i ärvdabalken. Kvarlåtenskapen i ett dödsbo
                      fördelas enligt arvsordningen om det inte finns ett
                      testamente.
                      <br />
                      <br />
                      Huvudregeln i svensk lag är att allt som en förälder ger
                      till sina barn som överstiger en normal gåva ska anses som
                      förskott på arv. Det medför att gåvan ska avräknas från
                      arvingens andel vid arvskiftet. Vill man undvika detta kan
                      man skriva ett gåvobrev som föreskriver att gåvan inte ska
                      räknas som förskott på arv.
                    </p>
                    <br />
                    <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                      Syftet med en framtidsfullmakt
                    </h3>
                    <p className="whitespace-pre-wrap font-interlight text-base font-semibold">
                      Det primära syftet med en framtidsfullmakt är att se till
                      att dina angelägenheter hanteras den dag du inte kan fatta
                      beslut och agera på egen hand, till exempel på grund av
                      hög ålder, sjukdom eller funktionsnedsättning. Genom att
                      utse en fullmaktshavare i förväg kan du känna dig trygg
                      med att dina ekonomiska angelägenheter, personliga
                      önskemål och vårdbeslut hanteras av någon som du litar på.
                    </p>
                  </div>
                </div>
                <div className="text-block pb-10">
                  <h3 className="mb-3 font-intermedium text-base font-semibold uppercase tracking-[.25em]">
                    Kan man göra sina barn arvlösa?
                  </h3>
                  <p className="whitespace-pre-wrap font-interlight text-base font-semibold">
                    Nej, bröstarvingar kan inte bli helt arvlösa enligt svensk
                    arvsrätt. Däremot kan man genom ett upprättat testamente
                    minska på arvet. Man kan testamentera bort den del som inte
                    är laglott.
                    <br />
                    <br />
                    Hoppas att den här artikeln gav dig den kunskap du letade
                    efter! Har du frågor är det bara att kontakta oss.
                  </p>
                  <br />
                </div>
                {/*<QuoteArticle />*/}
                {/*<TableArticle />*/}
                <div
                  data-section
                  id="steps"
                  data-title="Varför Fenix livsplanering?"
                  className="step-block scroll-my-40 pb-10 lg:scroll-my-20"
                >
                  <div className="inline-flex flex-col items-center justify-start gap-10">
                    <h2 className="text-center font-internormal text-base font-semibold uppercase leading-normal tracking-[4px]">
                      Varför Fenix livsplanering?
                    </h2>
                    <div className="flex flex-col items-start justify-start gap-8 md:flex-row lg:inline-flex lg:gap-20">
                      {steps.map((step, index) => (
                        <div
                          key={index}
                          className="inline-flex flex-col items-center justify-start gap-6"
                        >
                          <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full bg-[#E8E3D2] text-center text-[28px] font-semibold uppercase lg:h-[118px] lg:w-[118px] lg:text-[42px]">
                            {index + 1}
                          </div>
                          <div className="max-w-[194px] text-center text-[13px] font-normal leading-none">
                            {step.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <VideoItem />
                <br />
                <br />
                <br />
                <TrustboxArticle />
              </div>
            </article>
          </div>
        </div>
        <div
          data-section
          id="faq"
          data-title="FAQ"
          className="relative w-full border-t border-[#CECABA] text-deep-blue lg:border-t-0"
        >
          <div className="mx-auto px-5 pt-28 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:border-t lg:border-[#CECABA] lg:pt-24 lg:pb-32 xl:max-w-[1360px]">
            <div className="mx-auto max-w-[980px]">
              <h2 className="text-center font-interblack text-[28px] italic leading-[2.7rem] text-deep-blue xl:text-[48px] xl:leading-[4.5rem]">
                FAQ
              </h2>
            </div>
            <div className="mx-auto mt-3 max-w-[985px] pt-5 pb-24">
              <Accordion items={faqBlock} />
            </div>
          </div>
        </div>
        <div className="relative w-full bg-[#F3F0E6] text-deep-blue">
          <div className="mx-auto px-5 pt-12 pb-12 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-9 xl:max-w-[1360px]">
            <h2 className="mb-6 pt-8 font-intermedium text-base uppercase tracking-[.25em] lg:mb-3">
              Populära artiklar
            </h2>
            <ArticlesSlider
              articles={articlesFirstLevel.slice(0, 4)}
              init={false}
              quantity={4}
            />
          </div>
        </div>
      </div>
      <Footer />
    </ServiceSectionProvider>
  );
}
