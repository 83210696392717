import React, { useState } from "react";
import { Link } from "react-router-dom";

import { getBankIdIcon } from "../services/icons";
import { defaultPackagePrice } from "../services/partners";
import TrustBox from "./TrustBox";

export default function PackageSection({
  price,
  productButton,
}: {
  price?: string;
  productButton?: boolean;
}) {
  const [isHover, setIsHover] = useState(false);
  const renderLoginButton = (buttonClass: string, iconColor: string) => {
    return (
      <Link
        to="/login"
        className={
          `inline-flex items-center gap-2 rounded border-[1px] border-white bg-transparent py-3.5 px-4 font-intermedium text-[14px] uppercase leading-none tracking-[.25em] text-[#fff] hover:border-[#CECABA] hover:text-[#CECABA] lg:gap-3 lg:self-end` +
          buttonClass
        }
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover
          ? getBankIdIcon("#CECABA", "20", "100%")
          : getBankIdIcon(iconColor, "20", "100%")}
        Kom igång
      </Link>
    );
  };
  return (
    <div className="mx-auto px-5 pb-6 pt-7 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:px-5 lg:pb-16 lg:pt-8 xl:max-w-[1360px]">
      <div className="mx-auto flex w-full max-w-[1100px] flex-col items-center justify-start rounded-tl-[3px] rounded-tr-[3px] rounded-bl-lg rounded-br-lg bg-[#43262D] p-6 pb-10 lg:p-10">
        <div className="mb-10 self-stretch text-base font-semibold uppercase tracking-[0.25em] text-white">
          Fenix Family Livsplanering
        </div>
        <div className="mb-7 flex flex-col items-center justify-center gap-5 self-stretch lg:flex-row">
          <div className="flex max-w-[350px] flex-1 flex-col items-center justify-start gap-4">
            <div className="self-stretch text-center text-xs font-medium uppercase leading-[14.40px] tracking-widest text-[#CECABA]">
              Erbjudande
            </div>
            <div className="flex flex-col items-center justify-start self-stretch">
              <div className="text-center font-interblack text-[36px] italic leading-[40px] text-white lg:text-[64px] lg:leading-[70px]">
                {price}:-
              </div>
              {price !== defaultPackagePrice && (
                <div className="text-center italic">
                  <span className="text-base font-light leading-snug text-[#CECABA]">
                    Ord. pris{" "}
                  </span>
                  <span className="text-lg font-semibold leading-normal text-white">
                    2999:-
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="inline-flex max-w-[640px] shrink grow basis-0 flex-col items-start justify-start pt-6 lg:pt-0">
            <div className="self-stretch text-xs font-medium uppercase leading-[14.40px] tracking-widest text-white">
              Det här ingår
            </div>
            <ul className="flex  flex-col items-start justify-start self-stretch pl-8 pt-3">
              <li className="relative shrink grow basis-0 pb-4 text-[13px] font-normal italic leading-4 text-[#CECABA] before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                Inventering av din livssituation så att du ska få allt du
                behöver för en tryggare nutid och framtid.
              </li>
              <li className="relative shrink grow basis-0 pb-4 text-[13px] font-normal italic leading-4 text-[#CECABA] before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                Leverans av juridiska avtal.
              </li>
              <li className="relative shrink grow basis-0 pb-4 text-[13px] font-normal italic leading-4 text-[#CECABA] before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                Förvaring av och åtkomst till dina avtal i vårt digitala
                kassaskåp.
              </li>
              <li className="relative shrink grow basis-0 pb-4 text-[13px] font-normal italic leading-4 text-[#CECABA] before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                Förvaring och övervakning av ditt testamente.
              </li>
              <li className="relative shrink grow basis-0 pb-4 text-[13px] font-normal italic leading-4 text-[#CECABA] before:absolute before:top-0 before:-left-7 before:h-[10px] before:w-[15px] before:-rotate-45 before:border-b before:border-l before:border-[#A0C1AB] before:content-['']">
                Medskick till dina efterlevande.
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-center">
          {productButton ? (
            <Link
              to={"/livsplanering/"}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "auto",
                });
              }}
              className="block rounded-[5px] border border-[#FFFEFB] px-10 py-3 text-center text-sm uppercase tracking-[0.12em] text-white hover:border-[#CECABA] hover:text-[#CECABA]"
            >
              Läs mer om paketet
            </Link>
          ) : (
            renderLoginButton(` `, `#fff`)
          )}
        </div>
      </div>
      <div className="ml-0 mt-10 lg:ml-0 lg:mt-12">
        <TrustBox template={"5419b732fbfb950b10de65e5"} height={"24px"} />
      </div>
    </div>
  );
}
