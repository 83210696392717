import React from "react";
import { Link } from "react-router-dom";

import { getButtonArrow } from "../services/icons";

export default function ArticleGridItem({ item }: any) {
  return (
    <>
      <Link
        to={
          item.categoryFirstLevel.subcategories !== undefined
            ? "/artiklar/" +
              item.categoryFirstLevel.slug +
              "/" +
              item.categoryFirstLevel.subcategories.slug +
              "/" +
              item.link +
              "/"
            : "/artiklar/" +
              item.categoryFirstLevel.slug +
              "/" +
              item.link +
              "/"
        }
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "auto" });
        }}
        className=""
      >
        <div className="article-grid-item mb-7 rounded-tl-[13px] rounded-tr-[13px] lg:mb-16 lg:max-w-[365px]">
          <img
            src={item.thumb.href}
            alt={item.title}
            className="h-[217px] w-full rounded-tl-[13px] rounded-tr-[13px] object-cover object-center"
          />
          <p className="mt-5 font-intermedium text-xs uppercase text-[#736F62]">
            {item.categoryFirstLevel.title}
          </p>
          <div className="flex w-full flex-1 cursor-pointer justify-between hover:underline lg:justify-start">
            <h3 className="my-1 font-intermedium text-lg italic text-deep-blue">
              {item.title}
            </h3>
            <div className="ml-2">
              {getButtonArrow("#0C2632", "18", "100%")}
            </div>
          </div>
          <p className="pb-4 text-[13px] text-[#736F62]">
            {item.text.split(" ").slice(0, 15).join(" ") + "..."}
          </p>
        </div>
      </Link>
    </>
  );
}
