import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// eslint-disable-next-line import/namespace
import { menuFooter } from "../assets/data/datalist";
import { getBankIdIcon, getButtonArrow } from "../services/icons";

export default function Footer() {
  const textColor = "text-[#fff]";

  const renderLoginButton = () => {
    return (
      <Link
        to="/login"
        className={`flex flex-row items-center gap-2 rounded border-[1px] bg-[#0C2632] p-2 hover:border-[#6F86A0] hover:bg-[#6F86A0] lg:gap-3 lg:p-4`}
      >
        {getBankIdIcon("#ffffff", "20", "100%")}
        <span
          className={`font-intermedium text-[14px] uppercase leading-none tracking-widest ${textColor}`}
        >
          kom igång
        </span>
      </Link>
    );
  };
  return (
    <footer className="w-full bg-[#F3F0E6] ">
      <div className="flex w-full flex-col justify-between px-5 pt-24 pb-5 text-left sm:max-w-[640px] md:max-w-[768px] lg:mx-auto lg:max-w-[1024px] lg:flex-row xl:max-w-[1360px]  2k:max-w-[2460px] 4k:max-w-[3760px]">
        <div className="hidden w-3/12 flex-col items-start lg:flex">
          <h4 className="mb-7 font-interblack text-[36px] italic leading-[1.1] tracking-wide text-deep-blue">
            Planera för i morgon- så att du kan leva idag
          </h4>
          {renderLoginButton()}
        </div>
        <div className="mx-6 mb-10 flex flex-col lg:mx-0 lg:mb-0 lg:w-2/12">
          <ul className="flex flex-1 flex-col space-y-6">
            {menuFooter[0].firstColumn.map((item: any, index: number) => (
              <li key={index} className="font-intermedium">
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
                <Link
                  to={item.link}
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                  className="mb-4 flex text-base uppercase text-[#736F62] hover:text-[#6F86A0] lg:mb-6 lg:text-deep-blue"
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                  {item.title}
                </Link>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                {item.childMenu && (
                  <ul className="flex flex-col space-y-4">
                    {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                      item.childMenu.map((child: any, index: number) => (
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,react/jsx-key
                        <li
                          key={index}
                          className="text-deep-blue hover:text-[#6F86A0]"
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
                          <HashLink
                            scroll={(el) =>
                              el.scrollIntoView({
                                behavior: "auto",
                                block: "center",
                              })
                            }
                            to={child.link}
                            className="flex justify-between text-base font-medium lg:justify-start"
                          >
                            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                            {child.title}
                            <div className="ml-2">
                              {getButtonArrow("#0C2632", "18", "100%")}
                            </div>
                          </HashLink>
                        </li>
                      ))
                    }
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="mx-6 mb-10 flex flex-col lg:mx-0 lg:mb-0 lg:w-2/12">
          <ul className="flex flex-1 flex-col space-y-6">
            {menuFooter[0].secondColumn.map((item: any, index: number) => (
              <li key={index} className="font-intermedium">
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
                <Link
                  to={item.link}
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                  }}
                  className="mb-4 flex text-base uppercase text-[#736F62] hover:text-[#6F86A0] lg:mb-6 lg:text-deep-blue"
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                  {item.title}
                </Link>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                {item.childMenu && (
                  <ul className="flex flex-col space-y-4">
                    {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                      item.childMenu.map((child: any, index: number) => (
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,react/jsx-key
                        <li
                          key={index}
                          className="text-deep-blue hover:text-[#6F86A0]"
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
                          <HashLink
                            scroll={(el) =>
                              el.scrollIntoView({
                                behavior: "auto",
                                block: "center",
                              })
                            }
                            to={child.link}
                            className="flex justify-between text-base font-medium lg:justify-start"
                          >
                            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                            {child.title}
                            <div className="ml-2">
                              {getButtonArrow("#0C2632", "18", "100%")}
                            </div>
                          </HashLink>
                        </li>
                      ))
                    }
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="mx-6 flex flex-col lg:mx-0 lg:w-2/12">
          <ul className="flex flex-1 flex-col space-y-6">
            {menuFooter[0].thirdColumn.map((item: any, index: number) => (
              <li key={index} className="font-intermedium">
                <div className="mb-4 flex text-base uppercase text-deep-blue lg:mb-6">
                  {item.title}
                </div>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                {item.childMenu && (
                  <ul className="flex flex-col space-y-4">
                    <li className="text-deep-blue hover:text-[#6F86A0]">
                      <HashLink
                        to="/om-oss#vara-jurister"
                        scroll={(el) =>
                          el.scrollIntoView({
                            behavior: "auto",
                            block: "center",
                          })
                        }
                        className="flex justify-between text-base font-medium lg:justify-start"
                      >
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                        Våra jurister
                        <div className="ml-2">
                          {getButtonArrow("#0C2632", "18", "100%")}
                        </div>
                      </HashLink>
                    </li>
                    <li className="text-deep-blue hover:text-[#6F86A0]">
                      <a
                        className="flex justify-between text-base font-medium lg:justify-start"
                        href="tel:+46105201626"
                      >
                        Ring oss
                        <div className="ml-2">
                          {getButtonArrow("#0C2632", "18", "100%")}
                        </div>
                      </a>
                    </li>
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}
